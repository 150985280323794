import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';

// Utilities
import isEmpty from 'lodash/isEmpty';
import find from 'lodash.find';
import { hasProperty, observeAndUnmountReact, isNumeric, isBoolean } from '../../utilities/helper';
import I18n from '../../utilities/i18n';
import PostmongerStore from '../../utilities/postmonger';

import CustomSummaryDescription from './custom-summary-description';
import MessageTrigger from '../message-trigger';
import EventSelector from '../../components/message-trigger/event-selector';

const isApplicationSelected = (store) => {
	// Check if any type of message is selected
	if (!isEmpty(store) && hasProperty(store, 'messageConfiguration')) {
		return hasProperty(store.messageConfiguration, 'application') && !isEmpty(store.messageConfiguration.application);
	}

	return false;
};

function MessageTriggerView (scm) {
	const viewIndex = 2;
	const sectionKey = 'messageTrigger';
	const i18n = I18n(PostmongerStore.cultureCode);

	return {
		menu: {
			text: i18n.get('message_trigger')
		},
		spoke: {
			index: viewIndex + 1, // Majik expects non-array indexing
			text: i18n.get('message_trigger'),
			required: true,
			disabled: true,
			description: () => {
				const { store, views } = scm;
				const messageTriggerDatasource = store[sectionKey];
				const defaultDescription = i18n.get('inapp_message_trigger_call_to_action_desc').substring(0, i18n.get('inapp_message_trigger_call_to_action_desc').length - 1); // Reuse call to action description but remove `.` at the end
				
				// Do not display any summary until application is selected
				if (!isApplicationSelected(store)) {
					return `<div class="slds-text-color_weak">${defaultDescription}</div>`;
				}
				
				const spokeValidationState = find(views(), {
					name: sectionKey
				}).spoke.validationState;
				
				if (spokeValidationState === 'error') {
					return `<div>${i18n.get('finish_configuration')}</div>`;
				}
				
				if (PostmongerStore.isEditingOldActivity && (!messageTriggerDatasource.messageTriggerEvents || messageTriggerDatasource.messageTriggerEvents.length === 0)) {
					// If activity has existing data and it has old hardcoded appOpen event trigger but missing message trigger data, set hardcoded Message Trigger event data with App Open
					messageTriggerDatasource.messageTriggerEvents = [{
						pushEventDefinitionId: 'a4bf233e-96e0-48d9-b269-061eea322e1b',
						name: '$appOpen',
						description: 'App Open',
						eventType: 0,
						eventAttributes: [],
						type: 'System',
						id: 'a4bf233e-96e0-48d9-b269-061eea322e1b',
						trigger: 'always'
					}];
					
					messageTriggerDatasource.configuredAttributes = [];
				}
				
				if (messageTriggerDatasource.messageTriggerEvents && messageTriggerDatasource.messageTriggerEvents.length > 0) {
					return CustomSummaryDescription(i18n, messageTriggerDatasource.messageTriggerEvents, messageTriggerDatasource.configuredAttributes);
				}
				
				return `<div class="slds-text-color_weak">${defaultDescription}</div>`;
			},
			onRender: (element) => {
				// Add click event handlers for Show More/Show Less buttons
				element.querySelectorAll('.event-container').forEach((eventContainer) => {
					const configuredAttributesContainer = eventContainer.querySelector('.configured-attributes');
					
					if (configuredAttributesContainer.offsetHeight > 75) {
						eventContainer.classList.add('short-height');
					}
					
					if (eventContainer.querySelector('.show-more')) {
						eventContainer.querySelector('.show-more').addEventListener('click', (evt) => {
							const { target } = evt;
							target.closest('.event-container').classList.add('full-height');
							target.closest('.event-container').classList.remove('short-height');
						});
					}
					
					if (eventContainer.querySelector('.show-less')) {
						eventContainer.querySelector('.show-less').addEventListener('click', (evt) => {
							const { target } = evt;
							target.closest('.event-container').classList.remove('full-height');
							target.closest('.event-container').classList.add('short-height');
						});
					}
				});
			}
		},
		header: {
			text: i18n.get('message_trigger')
		},
		onShow: (args) => {
			const { element, sdk, store, resize, createModal, views } = args;
			const { activity } = sdk;
			const menuValidationState = find(views(), {
				name: sectionKey
			}).menu.validationState;
			
			resize('scm-full');

			element.innerHTML = `<div id="${sectionKey}"></div>`;

			observeAndUnmountReact(element.firstChild);

			if (!hasProperty(activity, 'configurationArguments')) {
				activity.configurationArguments = {};
			}
			
			const messageTriggerDatasource = store[sectionKey];
			
			// Render component with existing data
			const renderWithExistingData = (setMessageTriggerEvents, updateEventAttributes) => {
				if (!hasProperty(messageTriggerDatasource, 'messageTriggerEvents') || messageTriggerDatasource.messageTriggerEvents.length === 0) {
					return;
				}
				
				// If data exists, call actions with the data to set React props which triggers component render
				if (messageTriggerDatasource.messageTriggerEvents && messageTriggerDatasource.configuredAttributes) {
					setMessageTriggerEvents(messageTriggerDatasource.messageTriggerEvents);
					updateEventAttributes(messageTriggerDatasource.configuredAttributes);
				}
			};
			
			// Save React props to metadata for final payload
			const saveMessageTrigger = (messageTriggerEvents, configuredAttributes) => {
				if (messageTriggerEvents.length === 0) {
					delete messageTriggerDatasource.messageTriggerEvents;
					delete messageTriggerDatasource.configuredAttributes;
				} else {
					messageTriggerDatasource.messageTriggerEvents = messageTriggerEvents;
					messageTriggerDatasource.configuredAttributes = configuredAttributes;
				}
			};
			
			const onOpenEventSelector = (onSelectEvent) => {
				let selectedEvents = [];

				const updateTableHeight = () => {
					const totalHeight = document.querySelector('.scm-modal-content').offsetHeight;
					const headerHeight = document.querySelector('#event-selector-card .slds-card__header').offsetHeight;
					const bodyHeight = totalHeight - headerHeight - 15.5;
					
					document.getElementById('event-selector-card__body').style.height = `${bodyHeight}px`;
				};
				
				const eventSelectionModal = {
					id: 'test',
					footer: {
						buttons: [
							{
								id: 'event-select-btn',
								text: i18n.get('select'),
								onClick: 'onSelectEvent'
							}
						]
					},
					onInitialize: function () {
						// you can also access options on `this`
						this.element.innerHTML = '<div id="event-selector"></div>';
						
						ReactDOM.render(
							<EventSelector
								i18n={i18n}
								events={messageTriggerDatasource.messageTriggerEventOptions} // TODO: Replace with actual events data
								onClickEvent={(selection) => {
									selectedEvents = selection;
									
									// Enable Select button
									document.querySelector('#event-select-btn').removeAttribute('disabled');
								}}
							/>,
							document.getElementById('event-selector')
						);
						
						// Adjust table height whenever screen is resized
						window.onresize = updateTableHeight;
					},
					onSelectEvent: function () {
						this.close();
						
						// Set selected event data here to store
						onSelectEvent(selectedEvents);
					},
					onShow: function () {
						updateTableHeight(); // Adjust table height so that table is scrollable
						
						// Disbaled Select footer button by default on load.
						document.querySelector('#event-select-btn').setAttribute('disabled', 'disabled');
					},
					onClose: function () {
						// Reset onresize event
						window.onresize = null;
					}
				};
				
				createModal(eventSelectionModal).show();
			};

			ReactDOM.render(
				<Provider store={PostmongerStore.routeStore}>
					<IconSettings iconPath="/assets/icons">
						<MessageTrigger
							i18n={i18n}
							renderWithExistingData={renderWithExistingData}
							saveMessageTrigger={saveMessageTrigger}
							messageTriggerEventOptions={messageTriggerDatasource.messageTriggerEventOptions}
							onOpenEventSelector={onOpenEventSelector}
							menuValidationState={menuValidationState}
						/>
					</IconSettings>
				</Provider>,
				document.getElementById(sectionKey)
			);
		},
		configured: () => {
			const { store } = scm;
			const { messageTriggerEvents } = store[sectionKey];

			return messageTriggerEvents && messageTriggerEvents.length > 0;
		},
		onValidate: () => {
			const { store } = scm;
			const { messageTriggerEvents, configuredAttributes } = store[sectionKey];
			
			let isValid = false;
			messageTriggerEvents.forEach((event) => {
				// Check if there is any unconfigured placeholder attribute
				const unconfiguredAttributes = configuredAttributes.filter((attribute) => attribute.eventId === '');
				
				if (!event.trigger) {
					// No trigger has been selected yet
					isValid = false;
				} else if (event.trigger.toLowerCase() === 'always') {
					// If the event is either system or has ALWAYS trigger
					isValid = true;
				} else if (unconfiguredAttributes && unconfiguredAttributes.length > 0) {
					isValid = false;
				} else {
					// Custom events
					isValid = true;
					const configuredAttributesForSelectedEvent = configuredAttributes.filter((attribute) => attribute.eventId === event.id);
					
					// eslint-disable-next-line no-restricted-syntax
					for (const attribute of configuredAttributesForSelectedEvent) {
						// Check attribute value type mathes expected data type
						if (attribute.resourceType) {
							const type = attribute.resourceType.toLowerCase();
							if (type === 'number' && !isNumeric(attribute.value)) {
								isValid = false;
								break;
							}
							
							if (type === 'boolean' && !isBoolean(attribute.value)) {
								isValid = false;
								break;
							}
						}
						
						if (!(attribute.resource !== '' && attribute.operator !== '' && attribute.value !== '')) {
							isValid = false;
							break;
						}
					}
				}
			});
			
			return {
				errors: !isValid
			};
		}
	};
}

export default MessageTriggerView;
