import React from 'react';
import ReactDOM from 'react-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import I18n from '../../utilities/i18n';
import PostmongerStore from '../../utilities/postmonger';

import SplashScreen from '../../components/common/splash-screen';

const FtuxView = (scm, options) => {
	const i18n = I18n(PostmongerStore.cultureCode);
	
	function getProps () {
		const props = options?.getProps() || {};
		return {
			i18n: i18n,
			header: '',
			message: '',
			...props
		};
	}
	
	const header = {
		text: options?.headerText || '',
		onClick: 'scm:close',
		showIcon: true
	};
	
	const menu = {
		hidden: true,
		invisible: true
	};
	
	const footer = {
		hidden: true
	};
	
	const onShow = function (args) {
		const { element } = args;
		const viewId = 'ftux-view';
		
		element.innerHTML = `<div id="${viewId}"></div>`;
		ReactDOM.render(
			<IconSettings iconPath="/assets/icons">
				<SplashScreen
					{...getProps()}
				/>
			</IconSettings>,
			document.getElementById(viewId)
		);
	};
	
	return {
		header,
		menu,
		footer,
		onShow
	};
};

export default FtuxView;
