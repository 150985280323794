export default function (dom, configs) {
	const { content, previewHtml } = configs;

	const renderPreviewer = () => {
		const iframe = document.createElement('iframe');

		iframe.style.border = 'none';
		iframe.style.width = '294px';
		iframe.style.height = '522px';
		iframe.style.margin = '0 auto';

		iframe.onload = function () {
			const iframeElementContainer = iframe.contentDocument;

			// IE will trigger another load event on closing the contentDocument
			// That must be stopped!
			iframe.onload = null;

			iframeElementContainer.open();
			iframeElementContainer.writeln(previewHtml);
			iframeElementContainer.close();
		};

		dom.querySelector('.previewer').appendChild(iframe);
	};

	const render = () => {
		console.log(content); // Debugging purpose for header later on
		dom.innerHTML = `
			<div class="wrapper">
				<div class="header">
					<div class="message-name">${content.name}</div>
					<div class="warning slds-text-color_inverse-weak">This preview does not display personalization.</div>
				</div>
				<div class="previewer"></div>
			</div>
		`;

		renderPreviewer();
	};

	const initialize = () => {
		render();
	};

	initialize();
}
