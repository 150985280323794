export const SET_MESSAGE_PRIORITY_TOGGLE_VALUE = 'SET_MESSAGE_PRIORITY_TOGGLE_VALUE';
export const SET_MESSAGE_PRIORITY = 'SET_MESSAGE_PRIORITY';
export const SET_MESSAGE_DISPLAY = 'SET_MESSAGE_DISPLAY';
export const SET_DELAY_DISPLAY_TOGGLE_VALUE = 'SET_DELAY_DISPLAY_TOGGLE_VALUE';
export const SET_DELAY_DISPLAY_VALUE = 'SET_DELAY_DISPLAY_VALUE';
export const SET_DELAY_DISPLAY_UNIT = 'SET_DELAY_DISPLAY_UNIT';

export const setMessagePriorityToggleValue = (value) => ({
	type: SET_MESSAGE_PRIORITY_TOGGLE_VALUE,
	value
});

export const setMessagePriority = (value) => ({
	type: SET_MESSAGE_PRIORITY,
	value
});

export const setMessageDisplay = (optionType) => ({
	type: SET_MESSAGE_DISPLAY,
	optionType
});

export const setDelayDisplayToggleValue = (value) => ({
	type: SET_DELAY_DISPLAY_TOGGLE_VALUE,
	value
});

export const setDelayDisplayValue = (value) => ({
	type: SET_DELAY_DISPLAY_VALUE,
	value
});

export const setDelayDisplayUnit = (value) => ({
	type: SET_DELAY_DISPLAY_UNIT,
	value
});
