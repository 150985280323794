import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Input from '@salesforce/design-system-react/components/input';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Tooltip from '../common/tooltip';
import { setInputFilter } from '../../utilities/helper';
import { setDelayDisplayToggleValue, setDelayDisplayValue, setDelayDisplayUnit } from '../../actions/display-options';

function DelayDisplayAfterTrigger ({
	i18n
}) {
	const { isDelayDisplayToggleEnabled, delayDisplayValue, delayDisplayUnit, delayDisplayError } = useSelector((state) => state.DisplayOptions);
	const dispatch = useDispatch();
	const delayUnitOptions = [
		{ id: 'sec', label: i18n.get('seconds'), value: 'ss' },
		{ id: 'min', label: i18n.get('minutes'), value: 'mi' }
	];
	
	// Handle delay display value validation
	const delayDisplayErrorText = delayDisplayError ? i18n.get(`delay_message_display_validation_error_${delayUnitOptions.find((option) => option.value === delayDisplayUnit).id}`) : null;

	let delayDisplayValueRef;
	const isFirstUpdate = React.useRef(isDelayDisplayToggleEnabled);
	useEffect(() => {
		if (isFirstUpdate.current && delayDisplayValueRef) {
			setInputFilter(delayDisplayValueRef, (value) => /^\d*$/.test(value)); // Only allow number to be entered
		}
		
		isFirstUpdate.current = !isDelayDisplayToggleEnabled; // Apply input filter only when the toggle is about to be enabled
		
		// Handle value input error styling
		if (delayDisplayValueRef) {
			const valueInputFormEl = delayDisplayValueRef.closest('.slds-form-element');
			if (delayDisplayErrorText && !valueInputFormEl.classList.contains('slds-has-error')) {
				valueInputFormEl.classList.add('slds-has-error');
			}
			
			if (delayDisplayErrorText === null && valueInputFormEl.classList.contains('slds-has-error')) {
				valueInputFormEl.classList.remove('slds-has-error');
			}
		}
	});
	
	const getTriggerSelection = () => delayUnitOptions.filter((option) => option.value === delayDisplayUnit);
	
	const onChangeDelayDisplayValue = (event, { value }) => {
		dispatch(setDelayDisplayValue(value.length === 0 ? '' : parseInt(value, 10)));
	};
	
	return (
		<div className="delay-display container">
			<div className="container-heading">
				{i18n.get('delay_message_display')}
			</div>
			<Tooltip
				i18n={i18n}
				cid="delay_message_display_help_text"
			/>
			<div className="container-body">
				<Checkbox
					id="delay-display-toggle-checkbox"
					variant="toggle"
					labels={{
						toggleEnabled: i18n.get('enabled'),
						toggleDisabled: i18n.get('disabled')
					}}
					checked={isDelayDisplayToggleEnabled}
					onChange={(event, data) => {
						dispatch(setDelayDisplayToggleValue(data.checked));
					}}
				/>
				{isDelayDisplayToggleEnabled ? (
					<div className="components-container">
						<div className="controls">
							<Input
								id="delay-display-input"
								onChange={onChangeDelayDisplayValue}
								value={delayDisplayValue}
								inputRef={(ref) => {
									delayDisplayValueRef = ref;
								}}
							/>
							<Combobox
								events={{
									onSelect: (event, data) => {
										if (isEmpty(data.selection)) {
											// Prevent toggling of combobox selection
											return;
										}
										
										dispatch(setDelayDisplayUnit(data.selection[0].value));
									}
								}}
								id="delay-unit-combobox"
								multiple={false}
								options={delayUnitOptions}
								variant="readonly"
								selection={getTriggerSelection()}
							/>
						</div>
						<div className="break" />
						{delayDisplayErrorText ? (
							<span className="error-text">{delayDisplayErrorText}</span>
						) : ''}
					</div>
				) : ''}
			</div>
		</div>
	);
}

export default DelayDisplayAfterTrigger;
