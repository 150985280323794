import { handleActions } from 'redux-actions';
import { SET_ACTIVE_MESSAGE_TIMEFRAME, SET_DATE, SET_TIME, SET_TIMEZONE } from '../actions/active-message-timeframe';
import initialState from './InitialState';
import TimezoneUtils from '../utilities/timezone';

export default handleActions(
	{
		[SET_ACTIVE_MESSAGE_TIMEFRAME]: (state, action) => ({
			...state,
			activeMessageTimeframe: action.optionType
		}),
		[SET_DATE]: (state, action) => {
			if (action.date.type === 'start') {
				return ({
					...state,
					startDate: action.date.date
				});
			}

			return ({
				...state,
				endDate: action.date.date
			});
		},
		[SET_TIME]: (state, action) => {
			if (action.time.type === 'start') {
				return ({
					...state,
					startTime: action.time.time
				});
			}

			return ({
				...state,
				endTime: action.time.time
			});
		},
		[SET_TIMEZONE]: (state, action) => ({
			...state,
			selectedTimezone: TimezoneUtils.getTimezoneItemForComboboxById(action.timezone[0].id) // Keep the prop in fresh object form
		})
	},
	{
		startDate: initialState.startDate,
		startTime: initialState.startTime,
		endDate: initialState.endDate,
		endTime: initialState.endTime,
		activeMessageTimeframe: initialState.activeMessageTimeframe,
		selectedTimezone: initialState.selectedTimezone
	}
);
