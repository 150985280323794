import FtuxView from './ftux-view';
import I18n from '../../utilities/i18n';
import PostmongerStore from '../../utilities/postmonger';

const FtuxMessageTrigger = (scm) => {
	const i18n = I18n(PostmongerStore.cultureCode);
	
	function getProps () {
		return {
			header: i18n.get('message_trigger_data_mismatch_warning_header'),
			message: i18n.get('message_trigger_data_mismatch_warning_desc')
		};
	}
    
	return FtuxView(scm, {
		headerText: i18n.get('inapp_message_activity_summary'),
		getProps
	});
};

export default FtuxMessageTrigger;
