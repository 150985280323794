import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@salesforce/design-system-react/components/tooltip';
import { isNumeric, isBoolean } from '../../../utilities/helper';

function CustomAccordionSummary ({
	i18n,
	event,
	configuredAttributes,
	isAccordionExpanded
}) {
	let accordionSummaryClassName = 'accordion-summary';
	
	// If accordion is closed, show filter status
	if (!isAccordionExpanded) {
		let isFilterApplied;
		
		// Run validation logic and show Filter: Applied only when all the fields are configured
		if (!event.trigger) {
			// No trigger has been selected yet
			isFilterApplied = false;
		} else if (event.trigger.toLowerCase() === 'always') {
			// If the event is either system or has ALWAYS trigger
			isFilterApplied = false;
		} else {
			isFilterApplied = true;
			const configuredAttributesForSelectedEvent = configuredAttributes.filter((attribute) => attribute.eventId === event.id);
			
			// eslint-disable-next-line no-restricted-syntax
			for (const attribute of configuredAttributesForSelectedEvent) {
				// Check attribute value type mathes expected data type
				if (attribute.resourceType) {
					const type = attribute.resourceType.toLowerCase();
					if (type === 'number' && !isNumeric(attribute.value)) {
						isFilterApplied = false;
						break;
					}
					
					if (type === 'boolean' && !isBoolean(attribute.value)) {
						isFilterApplied = false;
						break;
					}
				}
				
				if (!(attribute.resource !== '' && attribute.operator !== '' && attribute.value !== '')) {
					isFilterApplied = false;
					break;
				}
			}
		}
		
		accordionSummaryClassName += isFilterApplied ? ' filter-applied' : ' filter-none';
	}
	
	const eventName = event.eventType === 0 ? i18n.get(`system_event_name_${event.name}`) : event.name; // Localize system event name
	
	return (
		<div className={accordionSummaryClassName}>
			<span className="title">{eventName}</span>
			<span className="event-type">
				<span className="separator">|</span>
				{`${i18n.get('event_type')}:`}
				<b>{i18n.get(`event_type_${event.eventType === 0 ? 'system' : 'custom'}`)}</b>
				<Tooltip
					id="event-type-tooltip"
					content={i18n.get('event_type_help_text')}
					variant="learnMore"
				/>
			</span>
			<span className="filter none">
				{`${i18n.get('filter')}:`}
				<b>{i18n.get('none')}</b>
			</span>
			<span className="filter applied">
				{`${i18n.get('filter')}:`}
				<b>{i18n.get('applied')}</b>
			</span>
		</div>
	);
}

CustomAccordionSummary.propTypes = {
	i18n: PropTypes.object.isRequired,
	event: PropTypes.object.isRequired,
	configuredAttributes: PropTypes.array.isRequired,
	isAccordionExpanded: PropTypes.bool.isRequired
};

export default CustomAccordionSummary;
