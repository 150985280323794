import React from 'react';
import PropTypes from 'prop-types';
import CustomTimeframeScheduler from './custom-timeframe-scheduler';
import DynamicTimeframe from './dynamic-timeframe';
import { ALWAYS_ACTIVE, CUSTOM_TIMEFRAME, DYNAMIC_TIMEFRAME } from '../../constants';

function ActiveMessageTimeframe ({
	i18n,
	activeMessageTimeframe,
	onClickActiveMessageTimeframe,
	selectedTimezone,
	onSelectDate,
	startDate,
	startTime,
	endDate,
	endTime,
	onSelectTime,
	onSelectTimezone,
	menuValidationState
}) {
	return (
		<div className="active-message-timeframe-container">
			<div className="heading">{i18n.get('set_message_expiration')}</div>
			<div className="slds-form-element">
				<div className="slds-form-element__control">
					<div className="radio-container">
						<input
							type="radio"
							id="always-active"
							value="always-active"
							name="options"
							checked={activeMessageTimeframe === ALWAYS_ACTIVE}
							onChange={onClickActiveMessageTimeframe}
						/>
						<label htmlFor="always-active">
							<div>
								<span className="slds-text-heading">{i18n.get('active_message_timeframe_always_active')}</span>
								<span className="slds-text-title">{i18n.get('message_expiration_always_active_desc')}</span>
							</div>
						</label>
					</div>
					<div className="radio-container">
						<input
							type="radio"
							id="custom-timeframe"
							value="custom-timeframe"
							name="options"
							checked={activeMessageTimeframe === CUSTOM_TIMEFRAME}
							onChange={onClickActiveMessageTimeframe}
						/>
						<label htmlFor="custom-timeframe">
							<div>
								<span className="slds-text-heading">{i18n.get('active_message_timeframe_custom_timeframe')}</span>
								<span className="slds-text-title">{i18n.get('message_expiration_custom_timeframe_desc')}</span>
								<CustomTimeframeScheduler
									i18n={i18n}
									classNames={activeMessageTimeframe === CUSTOM_TIMEFRAME ? 'custom-timeframe-scheduler-container' : 'hidden custom-timeframe-scheduler-container'}
									selectedTimezone={selectedTimezone}
									onSelectDate={onSelectDate}
									startDate={startDate}
									startTime={startTime}
									endDate={endDate}
									endTime={endTime}
									onSelectTime={onSelectTime}
									onSelectTimezone={onSelectTimezone}
								/>
							</div>
						</label>
					</div>
					<div className="radio-container">
						<input
							type="radio"
							id="dynamic-timeframe"
							value="dynamic-timeframe"
							name="options"
							checked={activeMessageTimeframe === DYNAMIC_TIMEFRAME}
							onChange={onClickActiveMessageTimeframe}
						/>
						<label htmlFor="dynamic-timeframe">
							<div>
								<span className="slds-text-heading">{i18n.get('message_expiration_dynamic_timeframe')}</span>
								<span className="slds-text-title">{i18n.get('message_expiration_dynamic_timeframe_desc')}</span>
								{ activeMessageTimeframe === DYNAMIC_TIMEFRAME ? (
									<DynamicTimeframe
										i18n={i18n}
										menuValidationState={menuValidationState}
									/>
								) : '' }
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	);
}

ActiveMessageTimeframe.propTypes = {
	i18n: PropTypes.object.isRequired,
	activeMessageTimeframe: PropTypes.string.isRequired,
	onClickActiveMessageTimeframe: PropTypes.func.isRequired,
	selectedTimezone: PropTypes.object.isRequired,
	onSelectDate: PropTypes.func.isRequired,
	startDate: PropTypes.string,
	startTime: PropTypes.string,
	endDate: PropTypes.string,
	endTime: PropTypes.string,
	onSelectTime: PropTypes.func.isRequired,
	onSelectTimezone: PropTypes.func.isRequired
};

export default ActiveMessageTimeframe;
