import React from 'react';
import PropTypes from 'prop-types';
import assign from 'lodash.assign';
import isEmpty from 'lodash/isEmpty';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Button from '@salesforce/design-system-react/components/button';

const defaultProps = {
	labels: {
		label: '',
		takeAction: 'Take Action When',
		addAttribute: 'Add Attribute',
		triggerAlways: 'Always (No Criteria)',
		triggerAll: 'All Attributes Are Met',
		triggerAny: 'Any Attribute Is Met',
		triggerPlaceholder: 'Select an Option',
		attributePlaceholder: 'Select an Option',
		operatorPlaceholder: 'Select an Option',
		valuePlaceholder: 'Enter a value...'
	}
};

class AttributeBuilder extends React.Component {
	constructor (props) {
		super(props);
		
		this.handleTriggerChange = this.handleTriggerChange.bind(this);
		this.getTriggerOptions = this.getTriggerOptions.bind(this);
	}
	
	componentDidMount () {
		this.localizeComboboxPlaceholders();
	}
	
	componentDidUpdate () {
		this.localizeComboboxPlaceholders();
	}
	
	/*
	 * Since DSR Expresion component does not provide a way to localize combobox's placeholder, we manually set placeholder attribute of combobox's input element
	*/
	localizeComboboxPlaceholders () {
		const labels = assign({}, defaultProps.labels, this.props.labels);
		
		const attributeComboboxInputEls = document.querySelectorAll('.slds-expression__row .slds-col:nth-of-type(1) input');
		attributeComboboxInputEls.forEach((el) => {
			if (el.value.length === 0) {
				el.setAttribute('placeholder', labels.attributePlaceholder);
			}
		});
		
		const operatorComboboxInputEls = document.querySelectorAll('.slds-expression__row .slds-col:nth-of-type(2) input');
		operatorComboboxInputEls.forEach((el) => {
			if (el.value.length === 0) {
				el.setAttribute('placeholder', labels.operatorPlaceholder);
			}
		});
		
		const valueInputEls = document.querySelectorAll('.slds-expression__row .slds-col:nth-of-type(3) input');
		valueInputEls.forEach((el) => {
			if (el.value.length === 0) {
				el.setAttribute('placeholder', labels.valuePlaceholder);
			}
		});
	}
	
	/*
	 * Generate and return trigger type objects, with labels either sent as props or using default props.
	 */
	getTriggerOptions () {
		const labels = assign({}, defaultProps.labels, this.props.labels);
		
		return [
			{ id: '1', label: labels.triggerAlways },
			{ id: '2', label: labels.triggerAll },
			{ id: '3', label: labels.triggerAny }
		];
	}
	
	/**
	 *  Returns object of trigger from trigger passed as prop
	 */
	getTriggerSelection () {
		const selection = this.props.selectedTriggerType;
		const Triggers = this.getTriggerOptions();
		const result = [];
		
		if (selection === 'always') {
			result.push(Triggers[0]);
		} else if (selection === 'all') {
			result.push(Triggers[1]);
		} else if (selection === 'any') {
			result.push(Triggers[2]);
		}
		
		return result;
	}
	
	handleTriggerChange (event, data) {
		if (isEmpty(data.selection)) {
			// Prevent toggling of combobox selection
			return;
		}
		
		const selection = data.selection[0].id;
		let trigger = '';
		
		if (selection === '1') {
			trigger = 'always';
		} else if (selection === '2') {
			trigger = 'all';
		} else if (selection === '3') {
			trigger = 'any';
		}
		
		this.props.events.onChangeTrigger(trigger);
	}

	render () {
		const {
			events,
			selectedTriggerType,
			isTriggerDisabled
		} = this.props;
		const labels = assign({}, defaultProps.labels, this.props.labels);
		
		const buttons = (selectedTriggerType.length > 0 && selectedTriggerType !== 'always') ? (
			<div className="slds-expression__buttons">
				<Button
					iconCategory="utility"
					iconName="add"
					iconPosition="left"
					id="add-condition-button"
					label={labels.addAttribute}
					onClick={events.onAddAttribute}
				/>
			</div>
		) : null;
		
		const body = (selectedTriggerType.length > 0 && selectedTriggerType !== 'always') ? (
			<ul>{this.props.children}</ul>
		) : null;
		
		return (
			<div className="attribute-builder-container">
				<div className="slds-expression">
					{labels.title ? (
						<h2 className="slds-expression__title">{labels.title}</h2>
					) : null }
					<div className="slds-expression__options trigger-type-container">
						<Combobox
							events={{
								onSelect: this.handleTriggerChange
							}}
							id="trigger-type-combobox"
							multiple={false}
							options={this.getTriggerOptions()}
							variant="readonly"
							labels={{
								label: labels.takeAction,
								placeholderReadOnly: labels.triggerPlaceholder
							}}
							selection={this.getTriggerSelection()}
							singleInputDisabled={isTriggerDisabled}
						/>
					</div>
					{body}
					{buttons}
				</div>
			</div>
		);
	}
}

AttributeBuilder.propTypes = {
	labels: PropTypes.object,
	events: PropTypes.object,
	selectedTriggerType: PropTypes.string,
	children: PropTypes.node,
	isTriggerDisabled: PropTypes.bool
};

export default AttributeBuilder;
