import React from 'react';
import PropTypes from 'prop-types';
import splashScreenIcon from '!file-loader!../../../static/images/splash-screen-icon.svg'; // eslint-disable-line import/no-unresolved

function SplashScreen ({
	i18n,
	header,
	message,
	tellMeMoreLink
}) {
	return (
		<div className="slds-grid slds-grid_align-center">
			<div className="slds-col slds-size--8-of-12">
				<img alt="" className="slds-p-bottom--medium" src={splashScreenIcon} />
				<div className="slds-text-align_center slds-m-bottom_small slds-text-heading_medium splash-header">
					{header || ''}
				</div>
				<div className="slds-text-align_center slds-text-color_weak splash-message">
					{message || ''}
				</div>
				{ tellMeMoreLink && (
					<div className="slds-text-align_center slds-m-bottom_medium splash-doc-link">
						<a
							href={tellMeMoreLink}
							target="_blank"
							rel="noopener noreferrer"
						>
							{i18n.get('majik_tell_me_more')}
						</a>
					</div>
				)}
			</div>
		</div>
	);
}

SplashScreen.propTypes = {
	i18n: PropTypes.object,
	header: PropTypes.string,
	message: PropTypes.string,
	tellMeMoreLink: PropTypes.string
};

export default SplashScreen;
