import React from 'react';
import PropTypes from 'prop-types';
import CallToAction from './call-to-action';
import EventDetail from './event-detail';

function MessageTrigger ({
	i18n,
	onOpenEventSelector,
	messageTriggerEvents,
	configuredAttributes,
	menuValidationState,
	isAttributeValueValid,
	valueValidationErrorMessagePerType,
	onAddAttribute,
	onDeleteAttribute,
	onUpdateAttribute,
	getOperatorsPerAttributeType,
	onChangeTrigger,
	shouldConfirmTriggerChange,
	onConfirmClearingAttributes,
	onDeleteEvent,
	isFetching
}) {
	return (
		<div className="message-trigger-container">
			{
				messageTriggerEvents.length === 0 ? (
					<CallToAction
						i18n={i18n}
						onOpenEventSelector={onOpenEventSelector}
						isFetching={isFetching}
					/>
				) : (
					<>
						<span className="section-subheading">{i18n.get('attribute_configuration_desc')}</span>
						<EventDetail
							i18n={i18n}
							messageTriggerEvents={messageTriggerEvents}
							configuredAttributes={configuredAttributes}
							componentValidationState={menuValidationState}
							isAttributeValueValid={isAttributeValueValid}
							valueValidationErrorMessagePerType={valueValidationErrorMessagePerType}
							onAddAttribute={onAddAttribute}
							onDeleteAttribute={onDeleteAttribute}
							onUpdateAttribute={onUpdateAttribute}
							getOperatorsPerAttributeType={getOperatorsPerAttributeType}
							onChangeTrigger={onChangeTrigger}
							shouldConfirmTriggerChange={shouldConfirmTriggerChange}
							onConfirmClearingAttributes={onConfirmClearingAttributes}
							onDeleteEvent={onDeleteEvent}
						/>
					</>
				)
			}
		</div>
	);
}

MessageTrigger.propTypes = {
	i18n: PropTypes.object.isRequired,
	onOpenEventSelector: PropTypes.func.isRequired,
	messageTriggerEvents: PropTypes.array.isRequired,
	configuredAttributes: PropTypes.array.isRequired,
	menuValidationState: PropTypes.string,
	isAttributeValueValid: PropTypes.func.isRequired,
	valueValidationErrorMessagePerType: PropTypes.object.isRequired,
	onAddAttribute: PropTypes.func.isRequired,
	onDeleteAttribute: PropTypes.func.isRequired,
	onUpdateAttribute: PropTypes.func.isRequired,
	getOperatorsPerAttributeType: PropTypes.func.isRequired,
	onChangeTrigger: PropTypes.func.isRequired,
	shouldConfirmTriggerChange: PropTypes.func.isRequired,
	onConfirmClearingAttributes: PropTypes.func.isRequired,
	onDeleteEvent: PropTypes.func.isRequired,
	isFetching: PropTypes.bool.isRequired
};

export default MessageTrigger;
