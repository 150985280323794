import { handleActions } from 'redux-actions';
import { SET_MESSAGE_PRIORITY_TOGGLE_VALUE, SET_MESSAGE_PRIORITY, SET_MESSAGE_DISPLAY, SET_DELAY_DISPLAY_TOGGLE_VALUE, SET_DELAY_DISPLAY_VALUE, SET_DELAY_DISPLAY_UNIT } from '../actions/display-options';
import initialState from './InitialState';

export const isDelayDisplayValid = (value, unit) => {
	// Validate logic: Value should be less than 24 hours
	let isValid = false;
	
	if (unit === 'ss') {
		isValid = value >= 0 && value <= 300;
	} else if (unit === 'mi') {
		isValid = value >= 0 && value <= 60;
	}
	
	return isValid;
};

export default handleActions(
	{
		[SET_MESSAGE_PRIORITY_TOGGLE_VALUE]: (state, action) => ({
			...state,
			isMessagePriorityToggleEnabled: action.value
		}),
		[SET_MESSAGE_PRIORITY]: (state, action) => ({
			...state,
			messagePriority: action.value
		}),
		[SET_MESSAGE_DISPLAY]: (state, action) => ({
			...state,
			messageDisplayValue: action.optionType
		}),
		[SET_DELAY_DISPLAY_TOGGLE_VALUE]: (state, action) => ({
			...state,
			isDelayDisplayToggleEnabled: action.value
		}),
		[SET_DELAY_DISPLAY_VALUE]: (state, action) => {
			const { value } = action;
			const unit = state.delayDisplayUnit;
			
			if (!isDelayDisplayValid(value, unit)) {
				return {
					...state,
					delayDisplayValue: value,
					delayDisplayError: 'Error'
				};
			}
			
			return {
				...state,
				delayDisplayValue: value,
				delayDisplayError: null
			};
		},
		[SET_DELAY_DISPLAY_UNIT]: (state, action) => {
			const value = state.delayDisplayValue;
			const unit = action.value;
			
			if (!isDelayDisplayValid(value, unit)) {
				return {
					...state,
					delayDisplayUnit: unit,
					delayDisplayError: 'Error'
				};
			}
			
			return {
				...state,
				delayDisplayUnit: unit,
				delayDisplayError: null
			};
		}
	},
	{
		isMessagePriorityToggleEnabled: initialState.isMessagePriorityToggleEnabled,
		messagePriority: initialState.messagePriority,
		messageDisplayValue: initialState.messageDisplayValue,
		isDelayDisplayToggleEnabled: initialState.isDelayDisplayToggleEnabled,
		delayDisplayValue: initialState.delayDisplayValue,
		delayDisplayUnit: initialState.delayDisplayUnit,
		delayDisplayError: initialState.delayDisplayError
	}
);
