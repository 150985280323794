import React from 'react';
import PropTypes from 'prop-types';

// Component
import AdvancedOptionsComponent from '../components/advanced-options';

// utilities
import { hasProperty } from '../utilities/helper';

class AdvancedOptions extends React.Component {
	constructor (props) {
		super(props);

		this.metaData = props.dataStore[props.sectionKey];
		if (!hasProperty(this.metaData, 'payload')) {
			this.metaData.payload = {};
		}
	}

	componentDidUpdate () {
		// Print out payload for debugging purpose
		const { dataStore } = this.props;
		console.log(dataStore);
	}

	render () {
		const { i18n } = this.props;
		return (
			<AdvancedOptionsComponent
				i18n={i18n}
				mobilePushReportingName={this.metaData.payload.mobilePushReportingName || ''}
			/>
		);
	}
}

AdvancedOptions.propTypes = {
	i18n: PropTypes.object.isRequired,
	dataStore: PropTypes.object.isRequired,
	sectionKey: PropTypes.string.isRequired
};

export default AdvancedOptions;
