export const SET_MESSAGE_TRIGGER_EVENTS = 'SET_MESSAGE_TRIGGER_EVENTS';
export const UPDATE_EVENT_ATTRIBUTES = 'UPDATE_EVENT_ATTRIBUTES';

export const setMessageTriggerEvents = (events) => ({
	type: SET_MESSAGE_TRIGGER_EVENTS,
	events
});

export const updateEventAttributes = (attributes) => ({
	type: UPDATE_EVENT_ATTRIBUTES,
	attributes
});
