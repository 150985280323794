export const SET_ACTIVE_MESSAGE_TIMEFRAME = 'SET_ACTIVE_MESSAGE_TIMEFRAME';
export const SET_DATE = 'SET_DATE';
export const SET_TIME = 'SET_TIME';
export const SET_TIMEZONE = 'SET_TIMEZONE';

export const setActiveMessageTimeframeOption = (optionType) => ({
	type: SET_ACTIVE_MESSAGE_TIMEFRAME,
	optionType
});

export const setDate = (date) => ({
	type: SET_DATE,
	date
});

export const setTime = (time) => ({
	type: SET_TIME,
	time
});

export const setTimezone = (timezone) => ({
	type: SET_TIMEZONE,
	timezone
});
