import React from 'react';
import PropTypes from 'prop-types';
import ActiveMessageTimeframe from './active-message-timeframe';

function MessageConfiguration ({
	i18n,
	activeMessageTimeframe,
	onClickActiveMessageTimeframe,
	onSelectDate,
	startDate,
	startTime,
	endDate,
	endTime,
	onSelectTime,
	onSelectTimezone,
	selectedTimezone,
	menuValidationState
}) {
	return (
		<div>
			<span className="section-subheading">{i18n.get('message_configuration_section_subheading')}</span>
			<div id="mobile-application-container" />
			<ActiveMessageTimeframe
				i18n={i18n}
				activeMessageTimeframe={activeMessageTimeframe}
				onClickActiveMessageTimeframe={onClickActiveMessageTimeframe}
				onSelectDate={onSelectDate}
				startDate={startDate}
				startTime={startTime}
				endDate={endDate}
				endTime={endTime}
				onSelectTime={onSelectTime}
				onSelectTimezone={onSelectTimezone}
				selectedTimezone={selectedTimezone}
				menuValidationState={menuValidationState}
			/>
		</div>
	);
}

MessageConfiguration.propTypes = {
	i18n: PropTypes.object.isRequired,
	activeMessageTimeframe: PropTypes.string.isRequired,
	onClickActiveMessageTimeframe: PropTypes.func.isRequired,
	onSelectDate: PropTypes.func.isRequired,
	startDate: PropTypes.string,
	startTime: PropTypes.string,
	endDate: PropTypes.string,
	endTime: PropTypes.string,
	onSelectTime: PropTypes.func.isRequired,
	onSelectTimezone: PropTypes.func.isRequired,
	selectedTimezone: PropTypes.object.isRequired
};

export default MessageConfiguration;
