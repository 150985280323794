import React from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import TimezoneUtils from '../../utilities/timezone';

export const getTimezoneSelection = (selectedTimezone, i18n) => (
	isEmpty(selectedTimezone) ? [] : [{
		...selectedTimezone,
		label: i18n.get(selectedTimezone.label)
	}]
);

function TimezonePicker ({ i18n, selectedTimezone, onSelectTimezone }) {
	return (
		<div className="timezone-picker">
			<Combobox
				id="timezone-dropdown"
				options={TimezoneUtils.getTimezonesForCombobox(i18n)}
				labels={{
					label: i18n.get('custom_timeframe_scheduler_timezone'),
					placeholderReadOnly: i18n.get('custom_timeframe_scheduler_timezone_picker_placeholder')
				}}
				variant="readonly"
				selection={getTimezoneSelection(selectedTimezone, i18n)}
				events={{
					onSelect: onSelectTimezone
				}}
				required
			/>
			<div className="desc">{i18n.get('custom_timeframe_scheduler_timezone_picker_desc')}</div>
		</div>
	);
}

TimezonePicker.propTypes = {
	i18n: PropTypes.object.isRequired,
	onSelectTimezone: PropTypes.func.isRequired,
	selectedTimezone: PropTypes.object.isRequired
};

export default TimezonePicker;
