export const SET_MOBILE_APP = 'SET_MOBILE_APP';
export const FETCH_MOBILE_APPS = 'FETCH_MOBILE_APPS';

export const setMobileApp = (app) => ({
	type: SET_MOBILE_APP,
	app
});

export const fetchMobileApps = {
	type: FETCH_MOBILE_APPS,
	fetch: {
		url: '/fuelapi/push-internal/v1/application?&$pageSize=1000&$page=1&$orderBy=name%20ASC'
	}
};
