import ReactDOM from 'react-dom';
import isEmpty from 'lodash/isEmpty';

// Generate unique UUID (Source code borrowed and edited from https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript)
export const generateUUID = () => {
	let date = new Date().getTime();

	if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
		date += performance.now(); // Use high-precision timer if available
	}

	/* eslint-disable no-mixed-operators */
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character) => {
		const randomNumber = (date + Math.random() * 16) % 16 | 0;
		date = Math.floor(date / 16);
		return (character === 'x' ? randomNumber : (randomNumber & 0x3 | 0x8)).toString(16);
	});
	/* eslint-enable no-mixed-operators */
};

export const hasProperty = (object, property) => Object.prototype.hasOwnProperty.call(object, property);

export const escape = (string) => {
	const escaped = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		"'": '&#39;',
		'"': '&quot;'
	};

	return string.replace(/[&<>'"]/g, (value) => escaped[value]);
};

export const unescape = (string) => {
	const regex = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34);/g;
	const unescaped = {
		'&amp;': '&',
		'&#38;': '&',
		'&lt;': '<',
		'&#60;': '<',
		'&gt;': '>',
		'&#62;': '>',
		'&apos;': "'",
		'&#39;': "'",
		'&quot;': '"',
		'&#34;': '"'
	};

	return string.replace(regex, (value) => unescaped[value]);
};

export const sanitizeHTML = (string) => {
	if (!string) {
		return '';
	}

	let html = string;

	if (typeof string !== 'undefined' && string.constructor === String && string.match(/&[^\s]*;/ig) === null) {
		html = unescape(string);
	}

	return escape(html);
};

export const html = (strings, ...interpolatedValues) => strings.reduce((total, current, index) => {
	let result = total;
	result += current;

	if (hasProperty(interpolatedValues, index)) {
		const value = String(interpolatedValues[index]);
		const isNoEscape = value.match(/^{.*}$/); // If value is wrapped with { and }

		result += isNoEscape ? value.substring(1, value.length - 1) : sanitizeHTML(value);
	}

	return result;
}, '');

export const removeHtmlTags = (text) => {
	if (text) {
		return text.replace(/<[^>]*>/g, '');
	}

	return '';
};

// Restricts input for the given textbox to the given inputFilter.
export const setInputFilter = (input, inputFilter) => {
	['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach((event) => {
		input.addEventListener(event, function () {
			if (inputFilter(this.value)) {
				this.oldValue = this.value;
				this.oldSelectionStart = this.selectionStart;
				this.oldSelectionEnd = this.selectionEnd;
			} else if (hasProperty(this, 'oldValue')) {
				this.value = this.oldValue;
				this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
			}
		});
	});
};

export const observeAndUnmountReact = (target) => {
	// Observe dom removal and when the wrapper DOM element is removed, unmount React component
	const observer = new MutationObserver((mutations) => {
		// Check for removed target
		mutations.forEach((mutation) => {
			const nodes = Array.from(mutation.removedNodes);
			if (nodes.indexOf(target) > -1) {
				// DOM element is removed
				ReactDOM.unmountComponentAtNode(target);
			}
		});
	});
	observer.observe(document.body, {
		subtree: true,
		childList: true
	});
};

export const isMessageSelected = (store) => {
	// Check if any type of message is selected
	if (!isEmpty(store) && hasProperty(store, 'messageDefinition')) {
		return hasProperty(store.messageDefinition, 'content') && !isEmpty(store.messageDefinition.content);
	}

	return false;
};

export const isNumeric = (num) => (typeof (num) === 'number' || (typeof (num) === 'string' && num.trim() !== '')) && !isNaN(num); // eslint-disable-line no-restricted-globals

export const isBoolean = (value) => value.toLowerCase() === 'true' || value.toLowerCase() === 'false';
