import React from 'react';
import PropTypes from 'prop-types';
import MessagePriority from './message-priority';
import DelayDisplayAfterTrigger from './delay-display-after-trigger';

function DeliveryOptions ({
	i18n,
	isMessagePriorityToggleEnabled,
	setMessagePriorityToggleValue,
	messagePriority,
	setMessagePriority
}) {
	return (
		<div>
			<span className="section-subheading">{i18n.get('display_options_section_subheading')}</span>
			<MessagePriority
				i18n={i18n}
				isMessagePriorityToggleEnabled={isMessagePriorityToggleEnabled}
				setMessagePriorityToggleValue={setMessagePriorityToggleValue}
				messagePriority={messagePriority}
				setMessagePriority={setMessagePriority}
			/>
			<DelayDisplayAfterTrigger
				i18n={i18n}
			/>
		</div>
	);
}

DeliveryOptions.propTypes = {
	i18n: PropTypes.object.isRequired,
	isMessagePriorityToggleEnabled: PropTypes.bool.isRequired,
	setMessagePriorityToggleValue: PropTypes.func.isRequired,
	messagePriority: PropTypes.string.isRequired,
	setMessagePriority: PropTypes.func.isRequired
};

export default DeliveryOptions;
