import React from 'react';
import PropTypes from 'prop-types';
import assign from 'lodash.assign';

import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';

const defaultProps = {
	labels: {
		title: '',
		cancel: 'Cancel',
		confirm: 'Confirm'
	}
};

function ConfirmationDialog ({
	dialogProps
}) {
	const {
		id,
		showDialog,
		labels,
		content,
		onCancel,
		onConfirm
	} = dialogProps;
	const finalLabels = assign({}, defaultProps.labels, labels);
	
	return (
		<Modal
			id={id || ''}
			className="confirmation-dialog"
			isOpen={showDialog}
			footer={[
				<Button
					key="cancel-btn"
					label={finalLabels.cancel}
					onClick={onCancel}
				/>,
				<Button
					key="confirm-btn"
					label={finalLabels.confirm}
					variant="brand"
					onClick={onConfirm}
				/>
			]}
			onRequestClose={onCancel}
			heading={finalLabels.title}
			dismissOnClickOutside={false}
			ariaHideApp={false}
		>
			{content || ''}
		</Modal>
	);
}

ConfirmationDialog.propTypes = {
	id: PropTypes.string,
	showDialog: PropTypes.bool,
	dialogProps: PropTypes.object.isRequired
};

export default ConfirmationDialog;
