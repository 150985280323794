import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash.find';

// Component
import MessageTriggerComponent from '../components/message-trigger';

// Actions
import { setMessageTriggerEvents, updateEventAttributes } from '../actions/message-trigger';

// utilities
import { isNumeric, isBoolean } from '../utilities/helper';
import { MESSAGE_TRIGGER_OPERATOR_MAP } from '../constants';

class MessageTrigger extends React.Component {
	constructor (props) {
		super(props);

		this.handleOpenEventSelector = this.handleOpenEventSelector.bind(this);
		this.onSelectEvent = this.onSelectEvent.bind(this);
		this.onAddAttribute = this.onAddAttribute.bind(this);
		this.onDeleteAttribute = this.onDeleteAttribute.bind(this);
		this.onUpdateAttribute = this.onUpdateAttribute.bind(this);
		this.getOperatorsPerAttributeType = this.getOperatorsPerAttributeType.bind(this);
		this.shouldConfirmTriggerChange = this.shouldConfirmTriggerChange.bind(this);
		this.onChangeTrigger = this.onChangeTrigger.bind(this);
		this.onConfirmClearingAttributes = this.onConfirmClearingAttributes.bind(this);
		this.onDeleteEvent = this.onDeleteEvent.bind(this);
		
		props.renderWithExistingData(props.setMessageTriggerEvents, props.updateEventAttributes);
	}
	
	componentDidUpdate () {
		this.props.saveMessageTrigger(this.props.messageTriggerEvents, this.props.configuredAttributes);
	}
	
	onSelectEvent (selectedEvents) {
		this.props.setMessageTriggerEvents(selectedEvents);
	}
	
	handleOpenEventSelector () {
		this.props.onOpenEventSelector(this.onSelectEvent);
	}
	
	// Validate value based on given attribute type
	isAttributeValueValid (type, value) {
		if (type.toLowerCase() === 'number' && !isNumeric(value)) {
			return false;
		}
		
		if (type.toLowerCase() === 'boolean' && !isBoolean(value)) {
			return false;
		}
		
		return true;
	}
	
	onAddAttribute (eventId) {
		const newAttributes = [
			...this.props.configuredAttributes,
			{
				eventId,
				resource: '',
				resourceType: '',
				operator: '',
				value: ''
			}
		];
		
		this.props.updateEventAttributes(newAttributes);
	}
	
	// Delete attribute data of given event by index
	onDeleteAttribute (eventId, i) {
		/*
			Deletion Steps
			1. Separate configuredAttributes array into two group - one for selected event, the other for the rest
			2. Delete the attribute from the selected event group by its index (i)
			3. Update configuredAttributes prop by concatenating both arrays
		*/
		const configuredAttributesForSelectedEvent = this.props.configuredAttributes.filter((attribute) => attribute.eventId === eventId);
		const restOfAttributes = this.props.configuredAttributes.filter((attribute) => attribute.eventId !== eventId);
		
		// When there is more than one attribute
		if (configuredAttributesForSelectedEvent.length > 1) {
			configuredAttributesForSelectedEvent.splice(i, 1);
			this.props.updateEventAttributes(configuredAttributesForSelectedEvent.concat(restOfAttributes));
		} else {
			// When there is only one attribute, just reset it with a blank attribute
			const newAttribute = [{
				eventId,
				resource: '',
				resourceType: '',
				operator: '',
				value: ''
			}];
			
			this.props.updateEventAttributes(newAttribute.concat(restOfAttributes));
		}
	}
	
	// Return list of localized operators for given attribute's type
	getOperatorsPerAttributeType (currentAttribute) {
		return currentAttribute.resourceType ? MESSAGE_TRIGGER_OPERATOR_MAP[currentAttribute.resourceType].map((operator) => ({
			...operator,
			label: this.props.i18n.get(operator.label)
		})) : [];
	}
	
	// Update attribute data - attribute, operator, and value
	onUpdateAttribute (i, val, type, currentEvent) {
		/*
			Update Logic
			1. Get only the attributes of the current event
			2. Target current attribute by index
			3. Update the current attribute data based on given type
			4. Update configuredAttributes prop by concatenating the attributes for selected event and the rest
		*/
		const configuredAttributesForSelectedEvent = this.props.configuredAttributes.filter((attribute) => attribute.eventId === currentEvent.id);
		const currentAttribute = configuredAttributesForSelectedEvent[i];
		
		if (type === 'value') {
			currentAttribute.value = val;
		} else if (type === 'resource') {
			currentAttribute[type] = val.selection[0].id;
			currentAttribute.resourceType = val.selection[0].type.toLowerCase();
			
			// Reset operator when the currently selected operator is no longer supported for the updated attribute's data type.
			const selectedOperator = find(this.getOperatorsPerAttributeType(currentAttribute), {
				id: currentAttribute.operator
			});
			
			if (!selectedOperator) {
				currentAttribute.operator = '';
			}
		} else if (type === 'operator') {
			currentAttribute[type] = val.selection[0].id;
		}
		
		const restOfAttributes = this.props.configuredAttributes.filter((attribute) => attribute.eventId !== currentEvent.id);
		
		this.props.updateEventAttributes(configuredAttributesForSelectedEvent.concat(restOfAttributes));
	}
	
	// Set trigger method of the given event
	setEventTrigger (trigger, currentEvent) {
		this.props.setMessageTriggerEvents(this.props.messageTriggerEvents.map((event) => {
			if (event.id === currentEvent.id) {
				return {
					...currentEvent,
					trigger
				};
			}
			
			return event;
		}));
	}
	
	shouldConfirmTriggerChange (trigger, currentEvent) {
		// Check if at least one attribute is configured
		let isAttributeConfigured = false;
		this.props.configuredAttributes.forEach((attribute) => {
			isAttributeConfigured = isAttributeConfigured || attribute.resource !== '' || attribute.operator !== '' || attribute.value !== '';
		});
		
		// if the trigger is being changed from non 'Always' ones to 'Always' with configured attributes, trigger change should be confirmed
		return currentEvent.trigger && currentEvent.trigger.toLowerCase() !== trigger && trigger === 'always' && isAttributeConfigured;
	}
	
	// Handle trigger combobox change
	onChangeTrigger (trigger, currentEvent) {
		// if trigger change needs confirmation, skip setting trigger because confirmation dialog will handle it
		if (this.shouldConfirmTriggerChange(trigger, currentEvent)) {
			return;
		}
		
		// Trigger is changed to none ALWAYS
		if (this.props.configuredAttributes.filter((attribute) => attribute.eventId === currentEvent.id).length === 0) {
			// If there's no configured attribute data for current event, add one attribute by default
			const restOfAttributes = this.props.configuredAttributes.filter((attribute) => attribute.eventId !== currentEvent.id);
			const newAttribute = [{
				eventId: currentEvent.id,
				resource: '',
				resourceType: '',
				operator: '',
				value: ''
			}];
			
			this.props.updateEventAttributes(newAttribute.concat(restOfAttributes));
		}
		
		// Set the trigger value of the current event.
		this.setEventTrigger(trigger, currentEvent);
	}
	
	onConfirmClearingAttributes (trigger, currentEvent) {
		// Reset configuredAttributes
		const restOfAttributes = this.props.configuredAttributes.filter((attribute) => attribute.eventId !== currentEvent.id);
		this.props.updateEventAttributes([].concat(restOfAttributes));
		
		// Update trigger
		this.setEventTrigger(trigger, currentEvent);
	}
	
	// Handle event delete
	onDeleteEvent (currentEvent) {
		// Delete current event
		this.props.setMessageTriggerEvents(this.props.messageTriggerEvents.filter((event) => event.name !== currentEvent.name));
		
		// Reset configuredAttributes - set configuredAttributes with attributes that are irrelevant to the deleted event
		this.props.updateEventAttributes(this.props.configuredAttributes.filter((attribute) => attribute.eventId !== currentEvent.id));
	}

	render () {
		const valueValidationErrorMessagePerType = {
			number: this.props.i18n.get('number_value_validation_message'),
			boolean: this.props.i18n.get('boolean_value_validation_message')
		};

		return (
			<MessageTriggerComponent
				i18n={this.props.i18n}
				onOpenEventSelector={this.handleOpenEventSelector}
				messageTriggerEvents={this.props.messageTriggerEvents}
				configuredAttributes={this.props.configuredAttributes}
				menuValidationState={this.props.menuValidationState}
				isAttributeValueValid={this.isAttributeValueValid}
				valueValidationErrorMessagePerType={valueValidationErrorMessagePerType}
				onAddAttribute={this.onAddAttribute}
				onDeleteAttribute={this.onDeleteAttribute}
				onUpdateAttribute={this.onUpdateAttribute}
				getOperatorsPerAttributeType={this.getOperatorsPerAttributeType}
				onChangeTrigger={this.onChangeTrigger}
				shouldConfirmTriggerChange={this.shouldConfirmTriggerChange}
				onConfirmClearingAttributes={this.onConfirmClearingAttributes}
				onDeleteEvent={this.onDeleteEvent}
				isFetching={this.props.isFetching}
			/>
		);
	}
}

MessageTrigger.propTypes = {
	i18n: PropTypes.object.isRequired,
	saveMessageTrigger: PropTypes.func.isRequired,
	setMessageTriggerEvents: PropTypes.func.isRequired,
	updateEventAttributes: PropTypes.func.isRequired,
	messageTriggerEvents: PropTypes.array,
	configuredAttributes: PropTypes.array,
	menuValidationState: PropTypes.string,
	messageTriggerEventOptions: PropTypes.array.isRequired,
	onOpenEventSelector: PropTypes.func.isRequired,
	renderWithExistingData: PropTypes.func.isRequired,
	isFetching: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
	messageTriggerEvents: state.MessageTrigger.messageTriggerEvents,
	configuredAttributes: state.MessageTrigger.configuredAttributes,
	isFetching: state.MessageTrigger.isFetching
});

const mapDispatchToProps = (dispatch) => ({
	setMessageTriggerEvents: (data) => {
		dispatch(setMessageTriggerEvents(data));
	},
	updateEventAttributes: (attributes) => {
		dispatch(updateEventAttributes(attributes));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageTrigger);
