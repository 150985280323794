import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Input from '@salesforce/design-system-react/components/input';
import Tooltip from '../common/tooltip';
import { setInputFilter } from '../../utilities/helper';

class MessagePriority extends React.Component {
	constructor (props) {
		super(props);

		this.messagePriorityCounterRef = React.createRef();
	}

	componentDidUpdate () {
		const { isMessagePriorityToggleEnabled } = this.props;
		if (!isMessagePriorityToggleEnabled) {
			return;
		}

		const messagePriorityInput = this.messagePriorityCounterRef.current.querySelector('input:not(.filtered)');

		if (messagePriorityInput) { // Apply filter only once
			setInputFilter(messagePriorityInput, (value) => /^\d*$/.test(value) && (value === '' || (parseInt(value, 10) > 0 && parseInt(value, 10) <= 100)));
			messagePriorityInput.removeAttribute('type'); // 'number' type is automatically added to the input element by DSR when min and max is provided. Since the input filter doesn't work with the number type, we need to manually remove it.
			messagePriorityInput.classList.add('filtered');
		}
	}

	render () {
		const {
			i18n,
			isMessagePriorityToggleEnabled,
			setMessagePriorityToggleValue,
			setMessagePriority,
			messagePriority
		} = this.props;

		return (
			<div className="message-priority container">
				<div className="container-heading">
					{i18n.get('message_priority_container_heading')}
				</div>
				<Tooltip
					i18n={i18n}
					cid="mp-messages-priority"
				/>
				<div className="container-body">
					<Checkbox
						id="message-priority-toggle-checkbox"
						variant="toggle"
						labels={{
							toggleEnabled: i18n.get('enabled'),
							toggleDisabled: i18n.get('disabled')
						}}
						checked={isMessagePriorityToggleEnabled}
						onChange={setMessagePriorityToggleValue}
					/>
					{isMessagePriorityToggleEnabled ? (
						<div className="message-priority-counter-container" ref={this.messagePriorityCounterRef}>
							<Input
								id="message-priority-counter-input"
								minValue={1}
								maxValue={100}
								onChange={setMessagePriority}
								value={messagePriority}
								variant="counter"
							/>
						</div>
					) : ''}
				</div>
			</div>
		);
	}
}

MessagePriority.propTypes = {
	i18n: PropTypes.object.isRequired,
	isMessagePriorityToggleEnabled: PropTypes.bool.isRequired,
	setMessagePriorityToggleValue: PropTypes.func.isRequired,
	messagePriority: PropTypes.string.isRequired,
	setMessagePriority: PropTypes.func.isRequired
};

export default MessagePriority;
