import moment from 'moment-timezone';

export const getISOStringWithTimezone = (date, time, timezoneName) => {
	const dateTime = moment(new Date(`${date} ${time}`)).format('YYYY-MM-DDTHH:mm:ss.SS');
	return moment.tz(dateTime, timezoneName).toISOString();
};

export const getCurrentDateISOStringWithTimezone = (timezoneName) => {
	const currentTime = moment().format('YYYY-MM-DDTHH:mm:ss.SS');

	return moment.tz(currentTime, timezoneName).toISOString();
};
