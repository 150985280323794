import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Tooltip from '@salesforce/design-system-react/components/tooltip';

function NameAndDescCell ({ item, ...props }) {
	const isSystemEvent = item.eventType === 0;
	let description = '';
	
	if (item.description) {
		description = isSystemEvent ? props.i18n.get(`system_event_desc_${item.name}`) : item.description;
	}
	
	return (
		<td>
			<div className="custom-cell">
				<div><strong>{isSystemEvent ? props.i18n.get(`system_event_name_${item.name}`) : item.name}</strong></div>
				{description !== '' ? (<div>{description}</div>) : ''}
			</div>
		</td>
	);
}
NameAndDescCell.displayName = DataTableCell.displayName;

function EventsTable ({
	i18n,
	events,
	selectedEvent,
	onClickRow,
	onSort,
	sortColumn,
	sortColumnDirection
}) {
	return (
		<DataTable
			items={events.map((event) => ({
				...event,
				type: i18n.get(`event_type_${event.type.toLowerCase()}`)
			}))}
			id="events-table"
			fixedLayout
			fixedHeader
			selectRows="radio"
			selection={selectedEvent}
			onRowChange={onClickRow}
			onSort={onSort}
		>
			<DataTableColumn
				label={i18n.get('name_and_description')}
				property="name"
				truncate
				width="50rem"
				sortable
				isSorted={sortColumn === 'name'}
				sortDirection={sortColumnDirection.name}
				i18n={i18n}
			>
				<NameAndDescCell />
			</DataTableColumn>
			<DataTableColumn
				label={(
					<div className="custom-header-cell">
						<span>{i18n.get('event_type')}</span>
						<Tooltip
							id="event-type-tooltip"
							content={i18n.get('event_type_help_text')}
							variant="learnMore"
						/>
					</div>
				)}
				property="type"
				truncate
				sortable
				isSorted={sortColumn === 'type'}
				sortDirection={sortColumnDirection.type}
			/>
		</DataTable>
	);
}

EventsTable.propTypes = {
	i18n: PropTypes.object.isRequired,
	events: PropTypes.array.isRequired,
	selectedEvent: PropTypes.array,
	onClickRow: PropTypes.func.isRequired,
	onSort: PropTypes.func.isRequired,
	sortColumn: PropTypes.string.isRequired,
	sortColumnDirection: PropTypes.object.isRequired
};

export default EventsTable;
