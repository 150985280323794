import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '@salesforce/design-system-react/components/accordion';
import AccordionPanel from '@salesforce/design-system-react/components/accordion/panel';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import Icon from '@salesforce/design-system-react/components/icon';

import find from 'lodash.find';
import isEmpty from 'lodash/isEmpty';

import ExpressionCondition from '@salesforce/design-system-react/components/expression/condition';
import AttributeBuilder from './attribute-builder';
import ConfirmationDialog from '../../common/confirmation-dialog';
import CustomAccordionSummary from './custom-accordion-summary';

class EventDetail extends React.Component {
	constructor (props) {
		super(props);
		
		this.state = {
			expandedPanels: {
				[props.messageTriggerEvents[0].id]: true
			},
			confirmationDialogProps: {}
		};
		
		this.handleDeleteAttribute = this.handleDeleteAttribute.bind(this);
		this.handleTriggerChange = this.handleTriggerChange.bind(this);
		this.toggleConfirmationDialogVisibility = this.toggleConfirmationDialogVisibility.bind(this);
		this.getTriggerType = this.getTriggerType.bind(this);
	}
	
	componentDidMount () {
		if (this.props.componentValidationState && this.props.componentValidationState === 'error') {
			this.validateExistingData();
		}
	}
	
	// Validate existing attribute data
	validateExistingData () {
		this.props.messageTriggerEvents.forEach((event) => {
			const accordionElement = document.querySelector(`[id="${event.id}-accordion-panel"]`);
			
			/*
				Validation Steps
				1. Check if trigger option is set. If not, show error state on trigger combobox
				2. If trigger option is set,
				 	Iterate all the form elements of each attribute row and show error state if the element is empty
				3. Value type check: if attribute is set, check if the expected attribute type matches the entered value type
			*/
			if (!event.trigger) {
				// If event trigger has not yet been selected, show error
				const triggerComboboxElement = accordionElement.querySelector('.slds-expression .slds-form-element:first-child');
				
				if (triggerComboboxElement) {
					this.updateFormElementErrorState(triggerComboboxElement, true);
				}
			} else {
				// Iterate each attribute row and check if any of form elements are empty
				const attributeRowEls = accordionElement.querySelectorAll('.slds-expression__row');
				attributeRowEls.forEach((el) => {
					const attributeComboboxEl = el.querySelectorAll('.slds-grid .slds-col .slds-form-element')[0];
					const operatorComboboxEl = el.querySelectorAll('.slds-grid .slds-col .slds-form-element')[1];
					const valueInputEl = el.querySelectorAll('.slds-grid .slds-col .slds-form-element')[2];
					
					// Check attribute combobox
					const attributeComboboxInput = attributeComboboxEl.querySelector('input');
					if (attributeComboboxInput.value === '') {
						this.updateFormElementErrorState(attributeComboboxEl, true);
					}
					
					// Check operator combobox
					const operatorComboboxInput = operatorComboboxEl.querySelector('input');
					if (operatorComboboxInput.value === '') {
						this.updateFormElementErrorState(operatorComboboxEl, true);
					}
					
					// Check value input field
					const valueInput = valueInputEl.querySelector('input');
					if (valueInput.value === '') {
						this.updateFormElementErrorState(valueInputEl, true);
					}
				});
				
				// Value type check
				const configuredAttributesForSelectedEvent = this.props.configuredAttributes.filter((attribute) => attribute.eventId === event.id);
				
				configuredAttributesForSelectedEvent.forEach((attribute, i) => {
					const attributeType = attribute.resourceType;
					const attributeValue = attribute.value;
					const targetValueFormElement = accordionElement.querySelector(`[id="expression-condition-${i}"] .slds-grid`).querySelectorAll('.slds-col .slds-form-element')[2];
					
					if (attributeType && attributeValue) {
						// Validate only when attribute type has been set
						if (!this.props.isAttributeValueValid(attributeType, attributeValue)) {
							this.addValidationErrorMessage(targetValueFormElement, this.props.valueValidationErrorMessagePerType[attributeType.toLowerCase()]);
						}
					}
				});
			}
		});
	}
	
	// Toggle accordion pannel
	togglePanel (event, data) {
		this.setState((state) => ({
			...state,
			expandedPanels: {
				[data.id]: !state.expandedPanels[data.id]
			}
		}));
	}
	
	// Return localized trigger type data
	getTriggerType (i, trigger) {
		if (i > 0) {
			if (trigger === 'all') {
				return this.props.i18n.get('logic_and');
			}
			if (trigger === 'any') {
				return this.props.i18n.get('logic_or');
			}
		}
		
		return '';
	}
	
	handleDeleteAttribute (eventId, i) {
		// Handle error state when deleting the last attribute
		const configuredAttributesForSelectedEvent = this.props.configuredAttributes.filter((attribute) => attribute.eventId === eventId);
		
		if (configuredAttributesForSelectedEvent.length === 1) {
			// Remove any error state of form elements
			const accordionElement = document.querySelector(`[id="${eventId}-accordion-panel"]`);
			const erroredElements = accordionElement.querySelectorAll('.slds-form-element.slds-has-error');
			if (erroredElements.length > 0) {
				erroredElements.forEach((el) => {
					this.removeValidationErrorMessage(el);
				});
			}
		}
		
		this.props.onDeleteAttribute(eventId, i);
	}
	
	// Add or remove error state to given form element
	updateFormElementErrorState (element, hasError) {
		if (hasError) {
			element.classList.add('slds-has-error');
		} else {
			element.classList.remove('slds-has-error');
		}
	}
	
	// Add given validation message and error state to given form element
	addValidationErrorMessage (element, errorMessage) {
		// Add error styling
		this.updateFormElementErrorState(element, true);
		
		if (errorMessage && errorMessage !== '') {
			const errorMessageEl = document.createElement('div');
			errorMessageEl.classList.add('slds-form-element__help');
			errorMessageEl.innerHTML = errorMessage;
			
			const existingErrorMessageEl = element.querySelector('.slds-form-element__help');
			
			if (existingErrorMessageEl === null) {
				// Append only once
				element.append(errorMessageEl);
			} else {
				existingErrorMessageEl.innerHTML = errorMessage;
			}
		}
	}
	
	// Remove validation message and error state from given form element
	removeValidationErrorMessage (element) {
		// Remove error styling
		this.updateFormElementErrorState(element, false);
		
		// Remove validation error message html element
		const targetElement = element.querySelector('.slds-form-element__help');
		if (targetElement !== null) {
			targetElement.remove();
		}
	}
	
	// Validate attribute value's data type based on selected attribute's data type
	validateValue (event, data, currentAttribute) {
		const isAttributeUpdated = !isEmpty(data.selection);
		const attributeType = isAttributeUpdated ? data.selection[0].type.toLowerCase() : currentAttribute.resourceType.toLowerCase();
		const targetValueFormElement = event.target.closest('.slds-grid').querySelectorAll('.slds-col .slds-form-element')[2]; // 3rd slds-form-element is for Value's input field
		
		let value;
		
		// If attribute is being updated, get value from current attribute
		if (isAttributeUpdated) {
			({ value } = currentAttribute);
		} else {
			({ value } = data);
		}
		value = value.trim();
		
		// Validation begins
		let isValid = true;
		if (value.length === 0) {
			// When value is empty and attribute is being updated, stop validation
			if (isAttributeUpdated) {
				// Clean up type mismatched error message
				const validationErrorEl = targetValueFormElement.querySelector('.slds-form-element__help');
				if (validationErrorEl !== null && this.props.valueValidationErrorMessagePerType[attributeType] !== validationErrorEl.innerHTML) {
					validationErrorEl.remove();
				}
				
				return;
			}
			
			if (this.props.componentValidationState === 'error') {
				// if component's validation state is already error, then empty value is now invalid
				isValid = false;
			}
		} else if (attributeType) {
			isValid = this.props.isAttributeValueValid(attributeType, value);
		}
		
		// Update error state
		if (!isValid) {
			this.addValidationErrorMessage(targetValueFormElement, this.props.valueValidationErrorMessagePerType[attributeType]);
		} else {
			this.removeValidationErrorMessage(targetValueFormElement);
		}
		
		// Clean up type mismatched error message
		const validationErrorEl = targetValueFormElement.querySelector('.slds-form-element__help');
		if (validationErrorEl !== null && this.props.valueValidationErrorMessagePerType[attributeType] !== validationErrorEl.innerHTML) {
			validationErrorEl.remove();
		}
	}
	
	// Handle trigger combobox change
	handleTriggerChange (trigger, currentEvent) {
		if (this.props.shouldConfirmTriggerChange(trigger, currentEvent)) {
			// Show confirmation dialog for clearing configured attributes
			this.setState({
				confirmationDialogProps: {
					id: 'clear-attribute-confirmation-dialog',
					showDialog: true,
					labels: {
						title: this.props.i18n.get('clear_event_criteria'),
						cancel: this.props.i18n.get('cancel'),
						confirm: this.props.i18n.get('continue')
					},
					content: (
						<section className="slds-p-around_large">
							<Icon
								className="slds-m-right_small"
								category="utility"
								colorVariant="warning"
								name="warning"
							/>
							{this.props.i18n.get('clear_event_criteria_desc')}
						</section>
					),
					onCancel: this.toggleConfirmationDialogVisibility,
					onConfirm: () => {
						// Close dialog
						this.toggleConfirmationDialogVisibility();
						this.props.onConfirmClearingAttributes(trigger, currentEvent);
					}
				}
			});
		} else {
			this.props.onChangeTrigger(trigger, currentEvent);
			
			// Remove error state on trigger Combobox
			const accordionElement = document.querySelector(`[id="${currentEvent.id}-accordion-panel"]`);
			const triggerComboboxElement = accordionElement.querySelector('.slds-expression .slds-form-element:first-child');
			this.updateFormElementErrorState(triggerComboboxElement, false);
		}
	}
	
	// Handle accordion action
	onSelectAccordionAction (option, currentAccordion) {
		if (option.value === 'delete') {
			this.setState({
				confirmationDialogProps: {
					id: 'delete-confirmation-dialog',
					showDialog: true,
					labels: {
						title: this.props.i18n.get('delete_event'),
						cancel: this.props.i18n.get('cancel'),
						confirm: this.props.i18n.get('continue')
					},
					content: (
						<section className="slds-p-around_large">
							<Icon
								className="slds-m-right_small"
								category="utility"
								colorVariant="warning"
								name="warning"
							/>
							{this.props.i18n.get('delete_event_desc')}
						</section>
					),
					onCancel: this.toggleConfirmationDialogVisibility,
					onConfirm: () => {
						this.props.onDeleteEvent(currentAccordion);
					}
				}
			});
		}
	}
	
	// Toggle visibility of configrmation dialog
	toggleConfirmationDialogVisibility () {
		this.setState({
			confirmationDialogProps: {
				...this.state.confirmationDialogProps,
				showDialog: !this.state.confirmationDialogProps.showDialog
			}
		});
	}

	render () {
		const { i18n } = this.props;
		
		const getAccordionAction = (currentAccordion) => (
			<Dropdown
				align="right"
				id={currentAccordion.id}
				buttonVariant="icon"
				buttonClassName="slds-shrink-none"
				iconCategory="utility"
				iconName="down"
				iconVariant="border-filled"
				onSelect={(option) => {
					this.onSelectAccordionAction(option, currentAccordion);
				}}
				options={[{
					label: this.props.i18n.get('delete'),
					value: 'delete'
				}]}
				iconSize="x-small"
			/>
		);
		
		const getAccordionSummary = (currentEvent) => (
			<CustomAccordionSummary
				i18n={this.props.i18n}
				event={currentEvent}
				configuredAttributes={this.props.configuredAttributes}
				isAccordionExpanded={!!this.state.expandedPanels[currentEvent.id]}
			/>
		);
		
		const generateResourceList = (attributes) => attributes.map((attribute) => ({
			id: attribute.name,
			label: attribute.name,
			type: attribute.type
		}));
		
		return (
			<div className="event-detail-container">
				<IconSettings iconPath="/assets/icons">
					{!isEmpty(this.state.confirmationDialogProps) ? (
						<ConfirmationDialog
							dialogProps={this.state.confirmationDialogProps}
						/>
					) : ''}
					<Accordion id="events-accordion">
						{this.props.messageTriggerEvents.map((event) => (
							<AccordionPanel
								expanded={!!this.state.expandedPanels[event.id]}
								id={event.id}
								panelContentActions={getAccordionAction(event)}
								key={event.id}
								onTogglePanel={(evt) => this.togglePanel(evt, event)}
								summary={getAccordionSummary(event)}
								title={event.eventType === 0 ? i18n.get(`system_event_name_${event.name}`) : event.name} // Localize system event name
							>
								<AttributeBuilder
									i18n={i18n}
									labels={{
										title: '',
										takeAction: i18n.get('message_trigger_option_header'),
										triggerAlways: i18n.get('message_trigger_option_always'),
										triggerAll: i18n.get('message_trigger_option_and'),
										triggerAny: i18n.get('message_trigger_option_or'),
										addAttribute: i18n.get('add_attribute'),
										triggerPlaceholder: i18n.get('select_an_option'),
										attributePlaceholder: i18n.get('select_an_option'),
										operatorPlaceholder: i18n.get('select_an_option'),
										valuePlaceholder: i18n.get('enter_a_value')
									}}
									events={{
										onChangeTrigger: (trigger) => {
											this.handleTriggerChange(trigger, event);
										},
										onAddAttribute: () => {
											this.props.onAddAttribute(event.id);
										}
									}}
									selectedTriggerType={event.trigger || ''}
									isTriggerDisabled={!event.eventAttributes || event.eventAttributes.length === 0}
								>
									{this.props.configuredAttributes.filter((attribute) => attribute.eventId === event.id).map((attribute, i) => (
										<ExpressionCondition
											/* eslint-disable-next-line react/no-array-index-key */
											key={i}
											id={`expression-condition-${i}`}
											labels={{
												label: this.getTriggerType(i, event.trigger),
												resource: i18n.get('attribute'),
												operator: i18n.get('operator'),
												value: i18n.get('value')
											}}
											events={{
												onChangeOperator: (evt, data) => {
													if (isEmpty(data.selection)) {
														// Prevent toggling of combobox selection
														return;
													}
													
													// Clear form element error state first
													const targetFormElement = evt.target.closest('.slds-grid').querySelectorAll('.slds-col .slds-form-element')[1];
													this.updateFormElementErrorState(targetFormElement, false);
													
													// Update value
													this.props.onUpdateAttribute(i, data, 'operator', event);
												},
												onChangeResource: (evt, data) => {
													if (isEmpty(data.selection)) {
														// Prevent toggling of combobox selection
														return;
													}
													
													// Clear form element error state first
													const targetFormElement = evt.target.closest('.slds-grid').querySelectorAll('.slds-col .slds-form-element')[0];
													this.updateFormElementErrorState(targetFormElement, false);
													
													// Validate value based on updated resource's type
													this.validateValue(evt, data, attribute);
													this.props.onUpdateAttribute(i, data, 'resource', event);
												},
												onChangeValue: (evt, data) => {
													this.validateValue(evt, data, attribute);
													this.props.onUpdateAttribute(i, data.value, 'value', event);
												},
												onDelete: () => {
													this.handleDeleteAttribute(event.id, i);
												}
											}}
											resourcesList={generateResourceList(event.eventAttributes || [])}
											resourceSelected={find(generateResourceList(event.eventAttributes || []), {
												id: attribute.resource
											})}
											operatorsList={this.props.getOperatorsPerAttributeType(attribute)}
											operatorSelected={find(this.props.getOperatorsPerAttributeType(attribute), {
												id: attribute.operator
											})}
											value={attribute.value}
										/>
									))}
								</AttributeBuilder>
							</AccordionPanel>
						))}
					</Accordion>
				</IconSettings>
			</div>
		);
	}
}

EventDetail.propTypes = {
	i18n: PropTypes.object.isRequired,
	messageTriggerEvents: PropTypes.array.isRequired,
	configuredAttributes: PropTypes.array.isRequired,
	componentValidationState: PropTypes.string,
	isAttributeValueValid: PropTypes.func.isRequired,
	valueValidationErrorMessagePerType: PropTypes.object.isRequired,
	onAddAttribute: PropTypes.func.isRequired,
	onDeleteAttribute: PropTypes.func.isRequired,
	onUpdateAttribute: PropTypes.func.isRequired,
	getOperatorsPerAttributeType: PropTypes.func.isRequired,
	onChangeTrigger: PropTypes.func.isRequired,
	shouldConfirmTriggerChange: PropTypes.func.isRequired,
	onConfirmClearingAttributes: PropTypes.func.isRequired,
	onDeleteEvent: PropTypes.func.isRequired
};

export default EventDetail;
