import React from 'react';
import PropTypes from 'prop-types';
import compassIcon from '!file-loader!../../../static/images/hero-image.svg'; // eslint-disable-line import/no-unresolved

function CallToAction ({
	i18n,
	onOpenEventSelector,
	isFetching
}) {
	return !isFetching ? (
		<div className="cta-container">
			<img src={compassIcon} alt="compass icon" className="slds-m-bottom_large" />
			<div className="heading slds-text-align_center slds-m-bottom_xx-small slds-text-heading_large">
				{i18n.get('inapp_message_trigger_call_to_action_header')}
			</div>
			<div className="subheading slds-text-align_center slds-m-bottom_medium slds-text-color_weak">
				{i18n.get('inapp_message_trigger_call_to_action_desc')}
			</div>
			<button
				className="button slds-button slds-button_brand"
				data-key="0"
				onClick={onOpenEventSelector}
			>
				{i18n.get('select')}
			</button>
		</div>
	) : '';
}

CallToAction.propTypes = {
	i18n: PropTypes.object.isRequired,
	onOpenEventSelector: PropTypes.func.isRequired,
	isFetching: PropTypes.bool.isRequired
};

export default CallToAction;
