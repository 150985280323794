import { successActionType } from '@salesforce-mc/fetch-maint/middleware/actions';
import { handleActions } from 'redux-actions';
import moment from 'moment';
import { SET_MOBILE_APP, FETCH_MOBILE_APPS } from '../actions/mobile-app-selection';
import initialState from './InitialState';
import I18n from '../utilities/i18n';
import { sanitizeHTML } from '../utilities/helper';
import PostmongerStore from '../utilities/postmonger';

const getLocaleText = (key) => {
	const { cultureCode } = PostmongerStore;
	const i18n = I18n(cultureCode);

	return i18n.get(key).toUpperCase();
};

const getExpiredHTMLText = (isExpired) => {
	if (isExpired) {
		const expired = getLocaleText('expired');

		return `<span class="expired">${expired.toUpperCase()}</span>`;
	}

	return '';
};

const isEnabled = (apnsAuthType, gcmEnabled, fcmFileName, apnsAuthKeyFileName, apnsEnabled) => {
	if (gcmEnabled || !!fcmFileName) {
		return true;
	}

	if (apnsAuthType === 'Token') {
		return !!apnsAuthKeyFileName;
	}

	return apnsEnabled;
};

const isApnsCertExpired = (apnsAuthType, apnsCertificateExpiration) => {
	// If ios sending option is p8,, apns doesn't expire
	if (apnsAuthType === 'Token') {
		return false;
	}

	const apnsExpiration = apnsCertificateExpiration ? moment(apnsCertificateExpiration) : false;
	const current = moment();

	return apnsExpiration ? apnsExpiration.isBefore(current) : false;
};

export const getComboboxItems = (items) => {
	const data = [];

	items.forEach((item) => {
		const { id, name, iconUrl, apnsAuthType, apnsEnabled, apnsCertificateExpiration, gcmEnabled, fcmFileName, apnsAuthKeyFileName } = item;
		const enabled = isEnabled(apnsAuthType, gcmEnabled, fcmFileName, apnsAuthKeyFileName, apnsEnabled);
		const expired = isApnsCertExpired(apnsAuthType, apnsCertificateExpiration);
		const disabled = !enabled;
		const expiredHTML = expired ? getExpiredHTMLText(expired) : '';
		const nameHTML = disabled ? `<span class="disabled-text">${sanitizeHTML(name)}</span>` : sanitizeHTML(name);

		data.push({
			id,
			name,
			value: expired ? `${expiredHTML} ${nameHTML}` : nameHTML,
			title: name,
			iconUrl: ((iconUrl) && (iconUrl.length > 0)) ? iconUrl : '',
			expired,
			disabled
		});
	});

	return data;
};

export default handleActions(
	{
		[SET_MOBILE_APP]: (state, action) => ({
			...state,
			selectedMobileApp: action.app
		}),
		[successActionType(FETCH_MOBILE_APPS)]: (state, action) => ({
			...state,
			mobileApps: getComboboxItems(action.payload.items)
		})
	},
	{
		selectedMobileApp: initialState.selectedMobileApp,
		mobileApps: initialState.mobileApps
	}
);
