export const SET_DYNAMIC_TIEMFRAME_VALUE = 'SET_DYNAMIC_TIEMFRAME_VALUE';
export const SET_DYNAMIC_TIMEFRAME_UNIT = 'SET_DYNAMIC_TIMEFRAME_UNIT';

export const setDynamicTimeframeValue = (value) => ({
	type: SET_DYNAMIC_TIEMFRAME_VALUE,
	value
});

export const setDynamicTimeframeUnit = (value) => ({
	type: SET_DYNAMIC_TIMEFRAME_UNIT,
	value
});
