import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_POPOVER_ARROW_POSITION } from '../../constants';

const popoverContainer = {
	position: 'relative',
	display: 'inline-block',
	verticalAlign: 'text-bottom'
};

const popoverTooltip = {
	position: 'absolute',
	width: '250px'
};

export default class Tooltip extends React.Component {
	constructor (props) {
		super(props);

		this.parent = React.createRef();
		this.popover = React.createRef();
		this.popoverBody = React.createRef();
	}

	componentDidMount () {
		const parent = this.parent.current;
		const popover = this.popover.current;
		const popoverBody = this.popoverBody.current;

		const { arrowPosition, i18n, cid } = this.props;

		popover.classList.add(`slds-nubbin_${arrowPosition}`);

		// Popover Body
		popoverBody.innerHTML = i18n.get(cid);

		// Make sure to open hyperlinks in a new tab
		if (popoverBody.querySelector('a') && !popoverBody.querySelector('a').hasAttribute('target')) {
			popoverBody.querySelector('a').setAttribute('target', 'blank');
		}

		parent.addEventListener('mouseover', () => {
			switch (arrowPosition) {
				case 'right':
					popover.style.top = `-${(popover.clientHeight / 2) - 12}px`;
					popover.style.right = '30px';
					break;

				case 'top':
					popover.style.top = '32px';
					popover.style.left = `-${(popover.clientWidth / 2) - 8}px`;
					break;

				case 'bottom':
					popover.style.bottom = '28px';
					popover.style.left = `-${(popover.clientWidth / 2) - 8}px`;
					break;

				default:
					popover.style.top = `-${(popover.clientHeight / 2) - 12}px`;
					popover.style.left = '30px';
					break;
			}

			popover.classList.remove('slds-fall-into-ground');
			popover.classList.add('slds-rise-into-ground');
		});

		parent.addEventListener('mouseout', () => {
			popover.classList.remove('slds-rise-into-ground');
			popover.classList.add('slds-fall-into-ground');
		});
	}

	render () {
		const { cid } = this.props;

		return (
			<div id={cid} style={popoverContainer} className="help-bubble" ref={this.parent}>
				<div className="slds-form-element">
					<div className="slds-form-element__icon slds-align-middle">
						<button className="slds-button slds-button_icon slds-button slds-button_icon" aria-describedby="help" aria-label="help">
							<svg className="slds-button__icon" aria-hidden="true">
								<use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#info" />
							</svg>
						</button>
					</div>
				</div>
				<div id="help" className="slds-popover slds-popover_tooltip slds-fall-into-ground" role="tooltip" style={popoverTooltip} ref={this.popover}>
					<div className="slds-popover__body" ref={this.popoverBody} />
				</div>
			</div>
		);
	}
}

Tooltip.propTypes = {
	i18n: PropTypes.object.isRequired,
	cid: PropTypes.string.isRequired,
	arrowPosition: PropTypes.string
};

Tooltip.defaultProps = {
	arrowPosition: DEFAULT_POPOVER_ARROW_POSITION
};
