import { handleActions } from 'redux-actions';
import { SET_DYNAMIC_TIEMFRAME_VALUE, SET_DYNAMIC_TIMEFRAME_UNIT } from '../actions/dynamic-timeframe';
import initialState from './InitialState';

export const isDynamicTimeframeValid = (value, unit) => {
	// Validate logic: Value should be less than 1 year
	let isValid = false;
	
	if (unit === 'mi') {
		isValid = value >= 0 && value <= 525601;
	} else if (unit === 'hh') {
		isValid = value >= 0 && value <= 8760;
	} else if (unit === 'dd') {
		isValid = value >= 0 && value <= 365;
	} else if (unit === 'ww') {
		isValid = value >= 0 && value <= 52;
	} else if (unit === 'mm') {
		isValid = value >= 0 && value <= 12;
	}
	
	return isValid;
};

export default handleActions(
	{
		[SET_DYNAMIC_TIEMFRAME_VALUE]: (state, action) => {
			const { value } = action;
			const unit = state.dynamicTimeframeUnit;
			
			if (!isDynamicTimeframeValid(value, unit)) {
				return {
					...state,
					dynamicTimeframe: value,
					dynamicTimeframeError: 'Error'
				};
			}
			
			return {
				...state,
				dynamicTimeframe: value,
				dynamicTimeframeError: null
			};
		},
		[SET_DYNAMIC_TIMEFRAME_UNIT]: (state, action) => {
			const value = state.dynamicTimeframe;
			const unit = action.value;
			
			if (!isDynamicTimeframeValid(value, unit)) {
				return {
					...state,
					dynamicTimeframeUnit: unit,
					dynamicTimeframeError: 'Error'
				};
			}
			
			return {
				...state,
				dynamicTimeframeUnit: unit,
				dynamicTimeframeError: null
			};
		}
	},
	{
		dynamicTimeframe: initialState.dynamicTimeframe,
		dynamicTimeframeUnit: initialState.dynamicTimeframeUnit,
		dynamicTimeframeError: initialState.dynamicTimeframeError
	}
);
