import React from 'react';
import PropTypes from 'prop-types';
import ReportingId from './reporting-id';

function AdvancedOptions ({
	i18n,
	mobilePushReportingName
}) {
	return (
		<div>
			<span className="section-subheading">{i18n.get('advanced_options_section_subheading')}</span>
			<ReportingId
				i18n={i18n}
				mobilePushReportingName={mobilePushReportingName}
			/>
		</div>
	);
}

AdvancedOptions.propTypes = {
	i18n: PropTypes.object.isRequired,
	mobilePushReportingName: PropTypes.string.isRequired
};

export default AdvancedOptions;
