import React from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from './date-time-picker';
import TimezonePicker from './timezone-picker';

function CustomTimeframeScheduler ({
	i18n,
	classNames,
	selectedTimezone,
	onSelectDate,
	startDate,
	startTime,
	endDate,
	endTime,
	onSelectTime,
	onSelectTimezone
}) {
	return (
		<div className={classNames}>
			<DateTimePicker
				i18n={i18n}
				selectedDate={startDate}
				selectedTime={startTime}
				type="start"
				onSelectDate={onSelectDate}
				onSelectTime={onSelectTime}
			/>
			<DateTimePicker
				i18n={i18n}
				selectedDate={endDate}
				selectedTime={endTime}
				type="end"
				onSelectDate={onSelectDate}
				onSelectTime={onSelectTime}
				earliestDate={startDate}
			/>
			<TimezonePicker
				i18n={i18n}
				selectedTimezone={selectedTimezone}
				onSelectTimezone={onSelectTimezone}
			/>
		</div>
	);
}

CustomTimeframeScheduler.propTypes = {
	i18n: PropTypes.object.isRequired,
	classNames: PropTypes.string.isRequired,
	selectedTimezone: PropTypes.object.isRequired,
	onSelectDate: PropTypes.func.isRequired,
	startDate: PropTypes.string,
	startTime: PropTypes.string,
	endDate: PropTypes.string,
	endTime: PropTypes.string,
	onSelectTime: PropTypes.func.isRequired,
	onSelectTimezone: PropTypes.func.isRequired
};

export default CustomTimeframeScheduler;
