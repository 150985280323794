// const Postmonger = require('postmonger');
import Postmonger from 'postmonger';
import { DEFAULT_CULTURE_CODE } from '../constants';

const PostmongerStore = {
	connection: null,
	jbPayload: {},
	cultureCode: DEFAULT_CULTURE_CODE,
	devMode: false,
	stackKey: 'qa1s1',

	getConnection: () => {
		if (!PostmongerStore.connection) {
			PostmongerStore.connection = new Postmonger.Session();
		}

		return PostmongerStore.connection;
	},

	routeStore: () => {}
};

export default PostmongerStore;
