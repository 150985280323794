const initialState = {
	activeMessageTimeframe: 'always-active',
	mobileApps: [],
	selectedMobileApp: [],
	selectedTimezone: {},
	isMessagePriorityToggleEnabled: false,
	messagePriority: '1',
	messageDisplayValue: 'app-open',
	messageTriggerEvents: [],
	startDate: '',
	startTime: '',
	endDate: '',
	endTime: '',
	mobilePushReportingName: '',
	configuredAttributes: [],
	isFetching: false,
	isDelayDisplayToggleEnabled: false,
	delayDisplayValue: '',
	delayDisplayUnit: 'ss',
	delayDisplayError: null,
	dynamicTimeframe: '',
	dynamicTimeframeUnit: 'mi',
	dynamicTimeframeError: null,
	isDisplayCancellationToggleEnabled: false,
	displayCancellationValue: 'cancelAfterExit'
};

export default initialState;
