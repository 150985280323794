import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// components
import DeliveryOptionsComponent from '../components/delivery-options/index';

// actions
import { setMessagePriorityToggleValue, setMessagePriority, setMessageDisplay, setDelayDisplayToggleValue, setDelayDisplayValue, setDelayDisplayUnit } from '../actions/display-options';

// utilities
import { hasProperty } from '../utilities/helper';

class DeliveryOptions extends React.Component {
	constructor (props) {
		super(props);

		this.metaData = props.dataStore[props.sectionKey];

		if (!hasProperty(this.metaData, 'payload')) {
			this.metaData.payload = {};
		}

		this.renderMessagePriorityWithExistingData();
		this.renderDelayDisplayWithExistingData();
	}

	renderMessagePriorityWithExistingData () {
		if (!hasProperty(this.metaData.payload, 'priority')) {
			this.props.setMessagePriorityToggleValue(null, {
				checked: false
			});

			return;
		}

		this.props.setMessagePriorityToggleValue(null, {
			checked: true
		});

		this.props.setMessagePriority(null, {
			value: this.metaData.payload.priority
		});
	}
	
	renderDelayDisplayWithExistingData () {
		if (!hasProperty(this.metaData, 'delayDisplayValue')) {
			this.props.setDelayDisplayToggleValue(false);

			return;
		}

		this.props.setDelayDisplayToggleValue(true);
		this.props.setDelayDisplayValue(this.metaData.delayDisplayValue);
		this.props.setDelayDisplayUnit(this.metaData.delayDisplayUnit);
	}

	saveMessagePriority () {
		if (this.props.isMessagePriorityToggleEnabled) {
			if (this.props.messagePriority === '') {
				delete this.metaData.payload.priority;

				// delete from metaData
				delete this.metaData.priority;
			} else {
				this.metaData.payload.priority = this.props.messagePriority;

				// save to metaData
				this.metaData.priority = this.props.messagePriority;
			}
		} else {
			delete this.metaData.payload.priority;

			// delete from metaData
			delete this.metaData.priority;
		}
	}
	
	saveDelayDisplay () {
		if (this.props.isDelayDisplayToggleEnabled) {
			if (this.props.delayDisplayValue === '') {
				// delete from metaData
				delete this.metaData.delayDisplayValue;
				delete this.metaData.delayDisplayUnit;
			} else {
				// save to metaData
				this.metaData.delayDisplayValue = this.props.delayDisplayValue;
				this.metaData.delayDisplayUnit = this.props.delayDisplayUnit;
			}
		} else {
			delete this.metaData.delayDisplayValue;
			delete this.metaData.delayDisplayUnit;
		}
	}

	componentDidUpdate () {
		this.saveMessagePriority();
		this.saveDelayDisplay();
		
		// print out payload for debugging purpose
		console.log(this.props.dataStore);
	}

	render () {
		return (
			<DeliveryOptionsComponent
				i18n={this.props.i18n}
				isMessagePriorityToggleEnabled={this.props.isMessagePriorityToggleEnabled}
				setMessagePriorityToggleValue={this.props.setMessagePriorityToggleValue}
				messagePriority={this.props.messagePriority}
				setMessagePriority={this.props.setMessagePriority}
				messageDisplayValue={this.props.messageDisplayValue}
				setMessageDisplay={this.props.setMessageDisplay}
			/>
		);
	}
}

DeliveryOptions.propTypes = {
	i18n: PropTypes.object.isRequired,
	dataStore: PropTypes.object.isRequired,
	sectionKey: PropTypes.string.isRequired,
	messageDisplayValue: PropTypes.string.isRequired,
	isMessagePriorityToggleEnabled: PropTypes.bool.isRequired,
	setMessagePriorityToggleValue: PropTypes.func.isRequired,
	messagePriority: PropTypes.string.isRequired,
	setMessagePriority: PropTypes.func.isRequired,
	setMessageDisplay: PropTypes.func.isRequired
};

const mapStateToProps = ({ DisplayOptions }) => ({
	isMessagePriorityToggleEnabled: DisplayOptions.isMessagePriorityToggleEnabled,
	messagePriority: DisplayOptions.messagePriority,
	messageDisplayValue: DisplayOptions.messageDisplayValue,
	isDelayDisplayToggleEnabled: DisplayOptions.isDelayDisplayToggleEnabled,
	delayDisplayValue: DisplayOptions.delayDisplayValue,
	delayDisplayUnit: DisplayOptions.delayDisplayUnit
});

const mapDispatchToProps = (dispatch) => ({
	setMessagePriorityToggleValue: (event, data) => {
		dispatch(setMessagePriorityToggleValue(data.checked));
	},
	setMessagePriority: (event, data) => {
		dispatch(setMessagePriority(data.value));
	},
	setMessageDisplay: (event) => {
		dispatch(setMessageDisplay(event.target.value));
	},
	setDelayDisplayToggleValue: (value) => {
		dispatch(setDelayDisplayToggleValue(value));
	},
	setDelayDisplayValue: (value) => {
		dispatch(setDelayDisplayValue(value));
	},
	setDelayDisplayUnit: (value) => {
		dispatch(setDelayDisplayUnit(value));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOptions);
