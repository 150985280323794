import React from 'react';
import PropTypes from 'prop-types';
import Button from '@salesforce/design-system-react/components/button';
import RadioGroup from '@salesforce/design-system-react/components/radio-group';
import Radio from '@salesforce/design-system-react/components/radio-group/radio';

function FilterDrawer ({
	i18n,
	showFilter,
	onClearFilter,
	onToggleFilterView,
	onChangeFilter,
	filterBy
}) {
	return (
		<div id="filter-drawer" className="slds-is-absolute">
			<div className={`drawer-view ${showFilter ? '' : 'hide'}`}>
				<div className="drawer-header">
					<h2 className="drawer-title">{i18n.get('filters')}</h2>
					<div className="drawer-actions">
						<Button
							label={i18n.get('clear_all')}
							onClick={onClearFilter}
							variant="base"
							className="clear"
						/>
						<Button
							iconName="chevronright"
							iconCategory="utility"
							iconVariant="border-filled"
							onClick={onToggleFilterView}
							variant="icon"
							className="close"
						/>
					</div>
				</div>
				<div className="drawer-body">
					<div className={`slds-box type ${filterBy.length > 0 ? 'filter-applied' : ''}`}>
						<div className="filter header slds-grid">
							<div className="slds-text-color_weak slds-text-heading_small slds-m-bottom_xx-small slds-col">{i18n.get('event_type')}</div>
						</div>
						<div className="body">
							<RadioGroup
								onChange={onChangeFilter}
							>
								<Radio
									id="system"
									labels={{
										label: i18n.get('event_type_system')
									}}
									value="system"
									checked={filterBy === 'system'}
								/>
								<Radio
									id="custom"
									labels={{
										label: i18n.get('event_type_custom')
									}}
									value="custom"
									checked={filterBy === 'custom'}
								/>
							</RadioGroup>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

FilterDrawer.propTypes = {
	i18n: PropTypes.object.isRequired,
	showFilter: PropTypes.bool.isRequired,
	onClearFilter: PropTypes.func.isRequired,
	onToggleFilterView: PropTypes.func.isRequired,
	onChangeFilter: PropTypes.func.isRequired,
	filterBy: PropTypes.string
};

export default FilterDrawer;
