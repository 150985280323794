import find from 'lodash.find';
import { SUPPORTED_MESSAGE_TRIGGER_OPERATORS, MESSAGE_TRIGGER_LOGIC_LOCALIZATION_MAP } from '../../constants';

export default function (i18n, messageTriggerEvents, configuredAttributes) {
	// Return operator's localized name by operator ID
	const getOperatorNameById = (operatorId) => i18n.get(find(SUPPORTED_MESSAGE_TRIGGER_OPERATORS, {
		id: operatorId
	}).label);
	
	// Return localized event trigger logic badge
	const getEventTriggerLogic = (trigger) => `
		<span class="slds-badge slds-badge_inverse">${i18n.get(MESSAGE_TRIGGER_LOGIC_LOCALIZATION_MAP[trigger])}</span>
	`;
	
	const renderAttributes = (event) => {
		// If selected event has Always trigger, display Filer: None
		if (event.trigger === 'always') {
			return `
				<div class="no-filter">
					${i18n.get('filter')}: <strong>${i18n.get('none')}</strong>
				</div>
			`;
		}
		
		// First, filter configured attributes by the selected event
		const configuredAttributesForSelectedEvent = configuredAttributes.filter((attribute) => attribute.eventId === event.id);
		
		return configuredAttributesForSelectedEvent.map((attribute, i) => `
			<div class="attribute-container">
				${i !== 0 ? getEventTriggerLogic(event.trigger) : ''}
				<strong>${attribute.resource !== '' ? attribute.resource : ''}</strong> ${attribute.operator !== '' ? getOperatorNameById(attribute.operator) : ''} <strong>${attribute.value}</strong>
			</div>
		`).join('');
	};
	
	const renderEvents = () => messageTriggerEvents.map((event) => `
		<div class="event-container">
			<div class="event-name">${event.eventType === 0 ? i18n.get(`system_event_name_${event.name}`) : event.name}</div>
			<div class="configured-attributes">
				${renderAttributes(event)}
			</div>
			<div class="show-more event-container-action">${i18n.get('show_more')}</div>
			<div class="show-less event-container-action">${i18n.get('show_less')}</div>
		</div>
	`).join('');

	const customSummaryView = `
		<div class="custom-summary-view">
			${renderEvents()}
		</div>
	`;
	
	return customSummaryView;
}
