import checkResponseStatus from '../utilities/check-response-status';
import fetchOptions from '../constants/fetch-options';

export default function fetchApplicationTriggerEvents (id) {
	const url = `/fuelapi/push-internal/v1/customEvent?pushApplicationId=${id}&includeSystemEvents=true&pageSize=1000`;

	return fetch(url, fetchOptions).then(checkResponseStatus).then((data) => {
		if (!data) {
			return {};
		}

		return data;
	});
}
