import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Input from '@salesforce/design-system-react/components/input';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { setInputFilter } from '../../utilities/helper';
import { setDynamicTimeframeValue, setDynamicTimeframeUnit } from '../../actions/dynamic-timeframe';

function DynamicTimeframe ({
	i18n,
	menuValidationState
}) {
	const { dynamicTimeframe, dynamicTimeframeUnit, dynamicTimeframeError } = useSelector((state) => state.DynamicTimeframe);
	const dispatch = useDispatch();
	const unitOptions = [
		{ id: 'minutes', label: i18n.get('minutes'), value: 'mi' },
		{ id: 'hours', label: i18n.get('hours'), value: 'hh' },
		{ id: 'days', label: i18n.get('days'), value: 'dd' },
		{ id: 'weeks', label: i18n.get('weeks'), value: 'ww' },
		{ id: 'months', label: i18n.get('months'), value: 'mm' }
	];
	
	// Handle timeframe value validation
	const timeframeErrorText = dynamicTimeframeError ? i18n.get(`duration_validation_error_${unitOptions.find((option) => option.value === dynamicTimeframeUnit).id}`) : null;
	
	let timeframeValueRef;
	const isFirstUpdate = React.useRef(true);
	useEffect(() => {
		if (isFirstUpdate.current && timeframeValueRef) {
			setInputFilter(timeframeValueRef, (value) => /^\d*$/.test(value)); // Only allow number to be entered
		}
		
		isFirstUpdate.current = false; // Apply input filter only once
		
		// Handle value input error styling
		if (timeframeValueRef) {
			const valueInputFormEl = timeframeValueRef.closest('.slds-form-element');
			
			// Handle empty value input when menu has already been validated
			if (menuValidationState === 'error' && dynamicTimeframe.length === 0 && !valueInputFormEl.classList.contains('slds-has-error')) {
				valueInputFormEl.classList.add('slds-has-error');
			}
			
			if (timeframeErrorText && !valueInputFormEl.classList.contains('slds-has-error')) {
				valueInputFormEl.classList.add('slds-has-error');
			}
			
			if (timeframeErrorText === null && dynamicTimeframe.length > 0 && valueInputFormEl.classList.contains('slds-has-error')) {
				valueInputFormEl.classList.remove('slds-has-error');
			}
		}
	});
	
	const getTriggerSelection = () => unitOptions.filter((option) => option.value === dynamicTimeframeUnit);
	
	const onChangeTimeframeValue = (event, { value }) => {
		dispatch(setDynamicTimeframeValue(value.length === 0 ? '' : parseInt(value, 10)));
	};
	
	return (
		<div className="dynamic-timeframe-container">
			<div className="container-body">
				<div className="components-container">
					<div className="controls">
						<Input
							id="dynamic-timeframe-input"
							onChange={onChangeTimeframeValue}
							value={dynamicTimeframe}
							inputRef={(ref) => {
								timeframeValueRef = ref;
							}}
							labels={i18n.get('duration')}
							placeholder={i18n.get('duration_placeholder')}
						/>
						<Combobox
							events={{
								onSelect: (event, data) => {
									if (isEmpty(data.selection)) {
										// Prevent toggling of combobox selection
										return;
									}
									
									dispatch(setDynamicTimeframeUnit(data.selection[0].value));
								}
							}}
							id="dynamic-timeframe-unit-combobox"
							multiple={false}
							options={unitOptions}
							variant="readonly"
							selection={getTriggerSelection()}
						/>
					</div>
					<div className="break" />
					{timeframeErrorText ? (
						<span className="error-text">{timeframeErrorText}</span>
					) : ''}
				</div>
			</div>
		</div>
	);
}

export default DynamicTimeframe;
