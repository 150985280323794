import { handleActions } from 'redux-actions';
import { SET_MESSAGE_TRIGGER_EVENTS, UPDATE_EVENT_ATTRIBUTES } from '../actions/message-trigger';
import initialState from './InitialState';
import PostmongerStore from '../utilities/postmonger';

export default handleActions(
	{
		[SET_MESSAGE_TRIGGER_EVENTS]: (state, action) => {
			const messageTriggerEvents = action.events;
			
			messageTriggerEvents.forEach((event) => {
				if (!event.eventAttributes || event.eventAttributes.length === 0) {
					// If an event does not have any attributes, manually set its trigger with always
					event.trigger = 'always';
				}
			});
			
			// Once this action is called, the activity is no longer editing old data
			PostmongerStore.isEditingOldActivity = false;
			
			return ({
				...state,
				messageTriggerEvents
			});
		},
		[UPDATE_EVENT_ATTRIBUTES]: (state, action) => ({
			...state,
			configuredAttributes: action.attributes
		})
	},
	{
		messageTriggerEvents: initialState.messageTriggerEvents,
		configuredAttributes: initialState.configuredAttributes,
		isFetching: initialState.isFetching
	}
);
