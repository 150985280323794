import { combineReducers } from 'redux';
import ActiveMessageTimeframe from './active-message-timeframe';
import MobileAppSelection from './mobile-app-selection';
import DisplayOptions from './display-options';
import MessageTrigger from './message-trigger';
import DynamicTimeframe from './dynamic-timeframe';

export default combineReducers({
	ActiveMessageTimeframe,
	MobileAppSelection,
	DisplayOptions,
	DynamicTimeframe,
	MessageTrigger
});
