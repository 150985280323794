import React from 'react';
import PropTypes from 'prop-types';

import IconSettings from '@salesforce/design-system-react/components/icon-settings';

import Card from '@salesforce/design-system-react/components/card';
import CardFilter from '@salesforce/design-system-react/components/card/filter';
import Button from '@salesforce/design-system-react/components/button';
import Tooltip from '@salesforce/design-system-react/components/tooltip';

import EventsTable from './events-table';
import FilterDrawer from './filter-drawer';

class EventSelector extends React.Component {
	constructor (props) {
		super(props);
		
		this.state = {
			events: props.events,
			originalEvents: props.events,
			sortColumn: 'name',
			sortColumnDirection: {
				name: 'asc',
				type: 'asc'
			},
			selectedEvent: [],
			searchString: '',
			showFilter: false,
			filterBy: ''
		};
	}
	
	componentDidMount () {
		// Manually sort name initially
		this.onSort({
			sortDirection: 'asc',
			property: 'name'
		});
	}
	
	getFilteredList = (list, searchString, filterBy) => list.filter((item) => {
		let result = true;
		
		// Searching
		if (searchString.length > 0) {
			result = String(item.name).toLowerCase().indexOf(searchString) > -1;
		}
		
		// Filtering
		if (filterBy.length > 0) {
			result = result && String(item.type).toLowerCase() === filterBy.toLowerCase();
		}
		
		return result;
	});
	
	onSearchEvent = (event) => {
		const searchString = String(event.target.value).toLowerCase();
		const filteredItems = this.getFilteredList(this.state.originalEvents, searchString, this.state.filterBy);
		
		this.setState({
			events: filteredItems,
			searchString // For future filtering
		});
	};
	
	onClickRow = (target, { selection }) => {
		console.log(selection);
		this.setState({
			selectedEvent: selection
		});
		
		this.props.onClickEvent(selection);
	};
	
	onSort = (sortColumn) => {
		if (!sortColumn) {
			return;
		}
		
		const { sortDirection, property } = sortColumn;

		const newState = {
			sortColumn: property,
			sortColumnDirection: {
				[property]: sortDirection
			},
			originalEvents: [...this.state.originalEvents],
			events: [...this.state.events]
		};

		newState.events = newState.originalEvents.sort((a, b) => {
			let val = 0;
			let compare1 = a[property];
			let compare2 = b[property];

			if (property === 'name' || property === 'type') {
				compare1 = String(compare1).toLowerCase();
				compare2 = String(compare2).toLowerCase();
			}

			if (compare1 > compare2) {
				val = 1;
			}
			if (compare1 < compare2) {
				val = -1;
			}

			if (sortDirection === 'desc') {
				val *= -1;
			}

			return val;
		});

		newState.originalEvents = newState.events.slice();
		// Update original channel data with sorted data

		newState.events = this.getFilteredList(newState.events, this.state.searchString, this.state.filterBy);

		this.setState(newState);
	};
	
	onToggleFilterView = () => {
		this.setState({
			showFilter: !this.state.showFilter
		});
	};
	
	onClearFilter = () => {
		// Reset filterBy value to clear the filters
		this.onChangeFilter({
			target: {
				value: ''
			}
		});
	};
	
	onChangeFilter = (evt) => {
		const filterBy = evt.target.value;
		const filteredItems = this.getFilteredList(this.state.originalEvents, this.state.searchString, filterBy);
		
		this.setState({
			events: filteredItems,
			filterBy // For future filtering
		});
	};

	render () {
		const {
			i18n
		} = this.props;
		
		return (
			<div className="event-selector-container">
				<IconSettings iconPath="/assets/icons">
					<div className="slds-grid slds-grid_vertical">
						<Card
							id="event-selector-card"
							filter={(
								<CardFilter
									placeholder={i18n.get('search_events')}
									onChange={this.onSearchEvent}
								/>
							)}
							headerActions={(
								<Button
									className={`${this.state.filterBy.length > 0 ? 'slds-is-selected' : ''}`}
									iconName="filterList"
									iconVariant="border"
									iconCategory="utility"
									onClick={this.onToggleFilterView}
									variant="icon"
								/>
							)}
							heading={(
								<div className="card-heading-container">
									<div className="heading scm-common-header-text slds-text-heading_medium slds-text-color_default slds-m-bottom_x-small">{i18n.get('event_selection')}</div>
									{/* eslint-disable react/no-danger */}
									<div className="subheading">
										<span>{i18n.get('event_selection_desc')}</span>
										<Tooltip
											id="event_selection_desc_help_text"
											content={(
												<div dangerouslySetInnerHTML={{ __html: i18n.get('event_selection_desc_help_text') }} />
											)}
											variant="learnMore"
											align="top left"
										/>
									</div>
									{/* eslint-enable react/no-danger */}
								</div>
							)}
						>
							<EventsTable
								i18n={i18n}
								events={this.state.events}
								selectedEvent={this.state.selectedEvent}
								onClickRow={this.onClickRow}
								onSort={this.onSort}
								sortColumn={this.state.sortColumn}
								sortColumnDirection={this.state.sortColumnDirection}
							/>
							<FilterDrawer
								i18n={i18n}
								showFilter={this.state.showFilter}
								onClearFilter={this.onClearFilter}
								onToggleFilterView={this.onToggleFilterView}
								onChangeFilter={this.onChangeFilter}
								filterBy={this.state.filterBy}
							/>
						</Card>
					</div>
				</IconSettings>
			</div>
		);
	}
}

EventSelector.propTypes = {
	i18n: PropTypes.object.isRequired,
	onClickEvent: PropTypes.func.isRequired,
	events: PropTypes.array.isRequired
};

export default EventSelector;
